import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button } from '@material-ui/core';
import { CustomTextField } from 'src/components/common/CustomTextField';
import { makeStyles } from '@material-ui/core/styles';
import * as yup from 'yup';
import { EMAIL } from 'src/constants/validation/validation';
import { CustomSelectForm } from 'src/components/common/CustomSelectFrom';
import { api } from 'src/api';
import { createClaimOptions } from 'src/utils/createOptions';
import { errorMessages } from 'src/constants/validation/errorMessages';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: '16px',
    alignItems: 'end',
    [theme.breakpoints.down('md')]: {
      gridAutoFlow: 'row',
    },
  },
}));

const employeeValidationSchema = yup.object().shape({
  email: EMAIL,
  claim_id: yup.string().required(errorMessages.REQUIRED_FIELD),
});

interface IAddEmployeeFormProps {
  onSubmit: any;
}

export const AddEmployeeForm: React.FC<IAddEmployeeFormProps> = props => {
  const { onSubmit } = props;
  const classes = useStyles();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    api.get('/multi-access').then(res => {
      const { data } = res;
      setOptions(data.map((item: any) => createClaimOptions(item.id, item.name)));
    });
  }, []);
  const formik = useFormik({
    initialValues: {
      email: '',
      claim_id: '',
    },
    validationSchema: employeeValidationSchema,
    onSubmit: data => {
      onSubmit(data, formik);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className={classes.root}>
      <CustomTextField
        name="email"
        label="Email"
        placeholder="Employee email"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.email}
        autoComplete="off"
        helperErrorText={formik.touched.email && formik.errors.email}
      />

      <CustomSelectForm
        name="claim_id"
        options={options}
        onHandleChange={formik.handleChange}
        value={formik.values.claim_id}
        label="Company"
        placeholder="Select employee company"
        helperErrorText={formik.touched.claim_id && formik.errors.claim_id}
        helperTextPosAbsl
      />
      <Button
        color="primary"
        variant="contained"
        style={{ height: '48px' }}
        type="submit"
        disabled={!formik.isValid || formik.isSubmitting}
      >
        Add an employee
      </Button>
    </form>
  );
};
