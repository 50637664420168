import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: 24,
  },

  title: {
    position: 'relative',
    color: '#5E6369',
    fontWeight: 'bold',
    fontSize: 24,

    '&::before': {
      position: 'absolute',
      content: "' '",
      width: 40,
      height: 2,
      backgroundColor: '#77CC21',
      bottom: -24,
      left: 0,
    },
  },
}));
