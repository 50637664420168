import React, { useState } from 'react';
import { theme as globalTheme } from '@theme/customTheme';
import { Typography, Box, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import clsx from 'classnames';
import { CenteredBox } from '@components/common/CenteredBox';
import { ReactComponent as MinusIcon } from '@assets/home/accordion/minus.svg';
import { ReactComponent as PlusIcon } from '@assets/home/accordion/plus.svg';

import { useStyles } from './styles';

const Accordion = withStyles({
  root: {
    background: '#ffffff',
    borderRadius: '8px 8px 0px 0px',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginBottom: '12px',
    },
    marginBottom: '12px',
  },
  expanded: {
    marginBottom: '12px',
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    boxShadow: globalTheme.shadows[4],
    padding: '12px 16px',
    backgroundColor: '#ffffff',
    marginBottom: -1,
    minHeight: 48,
    borderRadius: '8px',
    '&$expanded': {
      minHeight: 48,
    },
  },
  content: {
    '&$expanded': {
      margin: 0,
    },
    margin: 0,
  },
  expanded: {
    borderRadius: '8px 8px 0 0',
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#ffffff',
    boxShadow: globalTheme.shadows[4],
    borderRadius: '0 0 8px 8px',
  },
}))(MuiAccordionDetails);

export const CustomAccordion = ({ data = [] }) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState('panel1');

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box>
      {data.map(({ id, title, description, panel }) => (
        <Accordion key={id} square expanded={expanded === panel} onChange={handleChange(panel)}>
          <AccordionSummary aria-controls={`${id}-content`} id={id}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography
                  variant="body1"
                  className={clsx(classes.summatyText, {
                    [classes.summatyActiveText]: expanded === panel,
                  })}
                >
                  {title}
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <CenteredBox>{expanded === panel ? <MinusIcon /> : <PlusIcon />}</CenteredBox>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" className={classes.detailsText}>
              {description}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};
