// Calculations has been takes from
// https://xero.riabu.com/online_calculator.php

import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Slider } from '@material-ui/core';
import { CustomTextField } from 'src/components/common/CustomTextField';
import { useStyles } from './styles';

const CalculatorForm = ({ onExecuteCalculation }) => {
  const classes = useStyles();
  const [annualRevenue, setAnnualRevenue] = useState(50000);
  const [totalReceivables, setTotalReceivables] = useState(50000);
  const [totalOverdueReceivables, setTotalOverdueReceivables] = useState(50000);
  const [interestRatePerAnnum, setInterestRatePerAnnum] = useState(50000);

  const marks = [{
    value: 50000,
    label: '$ 50,000',
  }, {
    value: 50000000,
    label: '$ 50 million',
  }]

  const getCalculatedData = ({ annualRevenue, totalReceivables, totalOverdueReceivables, interestRatePerAnnum }) => {
    const interestRatePerAnnumPercent = interestRatePerAnnum / 100;

    const iDoS = annualRevenue / 365;
    const dso = Math.round(totalReceivables / iDoS); // Days Sales Outstanding

    const mi = (totalOverdueReceivables * interestRatePerAnnumPercent) / 12; // monthly interest
    const mi100 = totalOverdueReceivables * 0.3; // monthly interest 100 days
    const ms100 = totalOverdueReceivables * 0.3 * (interestRatePerAnnumPercent / 12); // monthly interest saving 100 days

    const mi250 = totalOverdueReceivables * 0.7; // monthly interest 250
    const ms250 = totalOverdueReceivables * 0.7 * (interestRatePerAnnumPercent / 12); // monthly interest saving 250 days

    return {
      iDoS,
      dso,
      mi,
      mi100,
      ms100,
      mi250,
      ms250,
    };
  };
  const getResults = () => {
    if (!(totalOverdueReceivables <= totalReceivables)) {
      document.getElementById("errorVal").innerText = "Total Overdue Receivables must be less than or equal to Total Receivables range!";
    } else {
      document.getElementById("errorVal").innerText = "";
      const results = getCalculatedData({ annualRevenue, totalReceivables, totalOverdueReceivables, interestRatePerAnnum });
      onExecuteCalculation(results);
    }
  }
  const getValues = (v, n) => {
    if (n === "annualRevenue") {
      setAnnualRevenue(v)
    }
    if (n === "totalReceivables") {
      setTotalReceivables(v)
    }
    if (n === "totalOverdueReceivables") {
      setTotalOverdueReceivables(v)
    }
    if (n === "interestRatePerAnnum") {
      setInterestRatePerAnnum(v)
    }
  }

  const formatValue = (number) => {
    if (typeof number == 'number') {
      return new Intl.NumberFormat('en', { maximumSignificantDigits: 3 }).format(number);
    }
    return number;
  }

  return (
    <>
      <Box mb={8}>
        <Typography style={{ color: '#5E6369', fontSize: 30, lineHeight: 1.5 }}>
          How much money can you save by taking control of collections?
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/* <CustomTextField
            name="annualRevenue"
            label="Step 1. Enter your Annual Revenue"
            placeholder="Enter amount e.g. 1230750"
            value={formik.values.annualRevenue}
            onChange={formik.handleChange}
            helperText={formik.errors.annualRevenue}
            error={Boolean(formik.errors.annualRevenue)}
          /> */}
          <Typography style={{ fontSize: "10px", color: "#A1A1A1", textTransform: "uppercase" }}>Step 1. Enter your Annual Revenue</Typography>
          <Typography className={classes.fontDollars}><sup>$ </sup>
            {formatValue(annualRevenue)}
          </Typography>
          <Slider step={1000} marks={marks} className={classes.slider} onChange={(e, v) => { getValues(v, "annualRevenue") }}
            defaultValue={0} aria-label="Default" min={50000} max={50000000} />
          {/* <Typography style={{ fontSize: "14px", color: "#A1A1A1" }}>Enter amount e.g. 1230750</Typography> */}
        </Grid>
        <Grid item xs={12}>
          {/* <CustomTextField
            name="totalReceivables"
            label="Step 2. Enter your Current Total Receivables"
            placeholder="Enter amount e.g. 1230750"
            value={formik.values.totalReceivables}
            onChange={formik.handleChange}
            helperText={formik.errors.totalReceivables}
            error={Boolean(formik.errors.totalReceivables)}
          /> */}
          <Typography style={{ fontSize: "10px", color: "#A1A1A1", textTransform: "uppercase" }}>Step 2. Enter your Current Total Receivables</Typography>
          <Typography className={classes.fontDollars}><sup>$ </sup>
            {formatValue(totalReceivables)}
          </Typography>
          <Slider step={1000} marks={marks} className={classes.slider} onChange={(e, v) => { getValues(v, "totalReceivables") }}
            defaultValue={0} aria-label="Default" min={50000} max={50000000} />
          {/* <Typography style={{ fontSize: "14px", color: "#A1A1A1" }}>Enter amount e.g. 1230750</Typography> */}
        </Grid>
        <Grid item xs={12}>
          {/* <CustomTextField
            type="number"
            name="totalOverdueReceivables"
            label="Step 3. Enter your Current Overdue Receivables"
            placeholder="Enter amount e.g. 1230750"
            value={formik.values.totalOverdueReceivables}
            onChange={formik.handleChange}
            helperText={formik.errors.totalOverdueReceivables}
            error={Boolean(formik.errors.totalOverdueReceivables)}
          /> */}
          <Typography style={{ fontSize: "10px", color: "#A1A1A1", textTransform: "uppercase" }}>Step 3. Enter your Current Overdue Receivables</Typography>
          <Typography className={classes.fontDollars}><sup>$ </sup>
            {formatValue(totalOverdueReceivables)}
          </Typography>
          <Slider step={1000} marks={marks} className={classes.slider} onChange={(e, v) => { getValues(v, "totalOverdueReceivables") }}
            defaultValue={0} aria-label="Default" min={50000} max={50000000} />
          <Typography id={"errorVal"} style={{ color: "red", fontSize: "10px", textTransform: "uppercase" }}></Typography>
          {/* <Typography style={{ fontSize: "14px", color: "#A1A1A1" }}>Enter amount e.g. 1230750</Typography> */}
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="interestRatePerAnnum"
            label="Step 4. Average interest rate you are paying Overdue Receivables (%)"
            placeholder="Enter amount e.g. 1230750"
            onChange={(e) => { getValues(+e.target.value, "interestRatePerAnnum") }}
          />
          {/* <Typography style={{ fontSize: "10px", color: "#A1A1A1", textTransform: "uppercase" }}>Step 4. Average interest rate you are paying Overdue Receivables %</Typography>
          <Slider onChange={(e, v) => { getValues(v, "interestRatePerAnnum") }}
            defaultValue={0} aria-label="Default" valueLabelDisplay="auto" min={0} max={1000} />
          <Typography style={{ fontSize: "14px", color: "#A1A1A1" }}>Enter amount e.g. 1230750</Typography> */}
        </Grid>
      </Grid>
      <Box mt={8} mb={3}>
        <Button className={classes.CalculateBtn} variant="contained" color="primary" onClick={getResults}>
          Calculate
        </Button>
      </Box>
    </>
  );
};

export default CalculatorForm;
