import { makeStyles } from '@material-ui/core/styles';
import { grey_dark, black_main } from '@theme/customTheme';
// import riabuCashFlow from 'src/assets/home/started-section/riabu-cash-flow.png';

export const useStyles = makeStyles(theme => ({
  firstSection: {
    padding: '30px 0 30px 0',
    '@media (max-width:480px)': {
      padding: '24px 0 40px 0',
    },
  },
  imageContainer: {
    [theme.breakpoints.down('sm')]: {
      order: -1,
    },
  },
  title: {
    color: '#040C14',
    fontSize: 40,
    fontWeight: 'bold',
    position: 'relative',

    '&::before': {
      position: 'absolute',
      content: "' '",
      width: 40,
      height: 2,
      backgroundColor: '#000000',
      bottom: -32,
      left: 0,
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  textContentCOntainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '50%',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  collectionTitle: {
    fontSize: 56,
    color: '#040C14',
    lineHeight: 1.2,

    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
      marginTop: 24,
    },
  },
  startedBox: {
    paddingBottom: '24px',
    // backgroundImage: `url(${riabuCashFlow})`,
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'contain',
    // backgroundPosition: '85%',
    // height: '574px',
    '@media (max-width:480px)': {
      display: 'flex',
      // textAlign: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundImage: 'none',
      height: 'fit-content',
      paddingBottom: '0px',
    },
  },
  graphicsMobileImg: {
    display: 'block',
    width: '100%',
    height: 'auto',
  },

  infoBtn: {
    background: '#DF1717',
    borderRadius: '4px',
    padding: '4px 19px',
    color: '#FFFFFF',
    textAlign: 'center',
    textTransform: 'uppercase',
    '@media (max-width:480px)': {
      padding: '0px 11px',
    },
  },
  infoBtnBox: {
    marginBottom: '24px',
    width: '104px',
    '@media (max-width:480px)': {
      marginBottom: '16px',
      width: '88px',
    },
  },
  titleBox: {
    marginBottom: '40px',
    width: '35vw',
    '@media (max-width:480px)': {
      marginBottom: '24px',
      width: '100%',
    },
  },
  getStartedBtnBox: {
    width: '240px',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  searchCompanyBox: {
    width: '70%',
    margin: '0 auto',
    boxShadow: '8px 16px 40px rgba(29, 43, 86, 0.07), 4px 4px 4px rgba(29, 43, 86, 0.04)',
    borderRadius: '8px',
    '@media (max-width:480px)': {
      width: '100%',
    },
  },
  searchPaper: {
    borderRadius: '8px',
    padding: '16px 24px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '@media (max-width:480px)': {
      padding: '16px 16px',
    },
  },
  // searchSelectOptions: {
  //   width: '70px',
  // },
  autocompleteWrapper: {
    flexGrow: 1,
    marginLeft: '32px',
    '@media (max-width:480px)': {
      marginLeft: '16px',
      width: '130px',
    },
  },
  searchAutocomplete: {
    '& input': {
      border: 'none',
      outline: 'none',
      boxSizing: 'border-box',
      color: black_main,
      fontSize: '16px',
      textOverflow: 'ellipsis',
      '&::placeholder': {
        fontSize: '16px',
        lineHeight: '24px',
        color: grey_dark,
        letterSpacing: '0.02em',
      },
      '@media (max-width:480px)': {
        width: '130px',
      },
    },
    '& .MuiAutocomplete-paper': {
      position: 'absolute',
      top: '0px',
      left: '0px',
    },
    '& .MuiList-root': {
      padding: '0px',
    },
    '@media (max-width:480px)': {
      width: '130px',
    },
  },
  selectCountriesBox: {
    paddingRight: '40px',
    position: 'relative',
    '&::after': {
      content: '""',
      display: 'block',
      width: '1px',
      height: '100%',
      background: grey_dark,
      position: 'absolute',
      right: '0px',
      top: '0px',
    },
    '@media (max-width:480px)': {
      paddingRight: '23px',
    },
  },
}));
