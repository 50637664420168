import { api } from '../index';

export const start = () => api.get('/accreditation/start');

export const resume = () => api.get('/accreditation/continue');

export const saveAnswer = data => api.post('/accreditation/send-answer', data);

export const saveFiles = data => api.post('/accreditation/save-files', data);

export const getUserData = () => api.get('/accreditation');

export const saveUserData = data => api.post('/accreditation/fill-fields', data);

export const oneMoreTime = () => api.get('/accreditation/one-more-time');
