import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { makeStyles } from '@material-ui/core/styles';

export interface IBaseChartProps {
  totalValue?: number;
  value: number;
  labelType?: 'percent' | 'count';
  size?: string;
}

export const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    width: ({ size = '64px' }: IBaseChartProps) => size,
    height: ({ size = '64px' }: IBaseChartProps) => size,
  },
  innerChart: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '13px',
    fontWeight: 600,
    fontFamily: 'Open Sans !important',
  },
  label: {},
  label__primary: {
    color: '#89CC21',
  },
  label__secondary: {
    color: '#b5b5b5',
  },
}));

export const BasePieChart: React.FC<IBaseChartProps> = props => {
  const classes = useStyles(props);
  const { totalValue = 100, value = 10, labelType = 'percent' } = props;

  return (
    <div className={classes.root}>
      <PieChart
        data={[{ value, color: '#89CC21' }]}
        totalValue={totalValue}
        lineWidth={20}
        labelPosition={50}
        rounded
        background="#E5E5E5"
        startAngle={320}
        labelStyle={{
          fontSize: '4px',
          fill: '#ffffff',
        }}
      />
      <div className={classes.innerChart}>
        {labelType === 'count' && (
          <div className={classes.label}>
            <span className={classes.label__primary}>{value}</span>
            <span className={classes.label__secondary}>{`/${totalValue}`}</span>
          </div>
        )}
        {labelType === 'percent' && (
          <div className={classes.label}>
            <span className={classes.label__primary}>{value}</span>
            <span className={classes.label__secondary}>%</span>
          </div>
        )}
      </div>
    </div>
  );
};
