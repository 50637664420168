import React from 'react';
import { isMobile } from '@utils/mobileDetect';
import { ExternalLayout } from 'src/components/ExternalLayout/ExternalLayout';
import { Container } from '@components/common/Container';
import { Calculator } from 'src/pages/Home/Calculator/Calculator';
import { FirstSection } from './FirstSection/FirstSection';
// import { SolveProblemsSection } from './SolveProblemsSection/SolveProblemsSection';
// import { SliderSection } from './SliderSection/SliderSection';
import { AcademySection } from './AcademySection/AcademySection';
// import { PartnerSection } from './PartnerSection/PartnerSection';
// import { OurBlogSection } from './OurBlogSection/OurBlogSection';
import { QuestionsSection } from './QuestionsSection/QuestionsSection';
import { SpamInfoSection } from './SpamInfoSection/SpamInfoSection';
import { Banner } from './Banner';
import { Situations } from './Situations';
import { HowItWorks } from './HowItWorks';
import { Benefits } from './Benefits';
import { Assesment } from './Assesment';
import { AboutUs } from './AboutUs';
import { TalkToAnExpert } from './TalkToAnExpert';
import HomepageQuiz from './HomepageQuiz/HomepageQuiz';
import Pledge2Pay from '../Pledge2Pay/Pledge2Pay';

export const Home = () => {
  return (
    <ExternalLayout>
      <Container>
        <Banner />
      </Container>
      <Container>
        <HomepageQuiz />
      </Container>
      <Container>
        <Situations />
      </Container>
      <Container>
        <HowItWorks />
      </Container>
      <Container>
        <Benefits />
      </Container>
      <Container>
        <Assesment adStyle={false} />
      </Container>
      <Container>
        <Calculator />
      </Container>
      <Container>
        <AboutUs />
      </Container>
      <Container>
        <FirstSection />
      </Container>
      <Container>
        <TalkToAnExpert />
      </Container>
      {/* <SolveProblemsSection /> */}
      {/* <Container>
        <SliderSection />
      </Container> */}
      <AcademySection />
      {/* <Container>
        <PartnerSection />
      </Container> */}
      {/* <OurBlogSection /> */}
      <QuestionsSection />
      {!isMobile && (
        <Container>
          <SpamInfoSection />
        </Container>
      )}
      <Container style={{margin:"1%"}}>
        <Pledge2Pay/>
      </Container>
    </ExternalLayout>
  );
};
