import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomStripeTextField } from '@components/common/CustomStripeTextField';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { GlobalLoader } from '@components/GlobalLoader/GlobalLoader';
import { showSuccessNotification, showErrorNotification } from '@components/common/Notifications';
import { err } from '@constants/messages';

import { Grid, Button } from '@material-ui/core';

import { getUser } from '@store/thunks/auth';

import { useFormik } from 'formik';
import * as yup from 'yup';
import * as validation from '@constants/validation/validation';

import { useStyles } from './styles';

export const StripeForm = ({ onHandleToggleStripeModalVisability, apiRequest, withoutLogin }) => {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const createSubscriptionRequest = async billingData => {
    let res;
    setLoading(true);
    try {
      res = await apiRequest(billingData);
      if (res.success) {
        if (withoutLogin) {
          onHandleToggleStripeModalVisability();
          showSuccessNotification(res.data, 4000);
        } else {
          onHandleToggleStripeModalVisability();
          showSuccessNotification(res.data, 4000);
          const token = JSON.parse(localStorage.jwt);
          dispatch(getUser(token.jwt));
        }
      }
    } catch (e) {
      if (e?.response?.status === 422) {
        showErrorNotification(e?.response?.data?.message);
      } else {
        showErrorNotification(err.DEFAULT);
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      card: false,
    },
    validationSchema: yup.object().shape({
      card: validation.CARD,
    }),
    onSubmit: async () => {
      setLoading(true);

      if (!stripe || !elements) {
        return;
      }

      const card = elements.getElement(CardElement);
      try {
        const price_id = localStorage.getItem('price_id');
        const stripeName = localStorage.getItem('stripe_name');
        const stripe_coupon = localStorage.getItem('stripe_coupon');
        const stripeResponse = await stripe.createToken(card);
        // card_id for accreditation
        createSubscriptionRequest({
          stripe_token: stripeResponse.token.id,
          card_id: stripeResponse.token.card.id,
          stripe_coupon,
          price_id,
          stripe_name: stripeName,
        });
      } catch (e) {
        setLoading(false);
        showErrorNotification(err.DEFAULT);
      }
    },
  });
  return (
    <div className={classes.formWrapper}>
      {loading && <GlobalLoader />}
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomStripeTextField
              name="card"
              type="card"
              label="Credit or debit card"
              helperText={formik.touched.card && formik.errors.card}
              error={Boolean(formik.touched.card && formik.errors.card)}
              onSetFieldTouched={formik.setFieldTouched}
              onSetFieldValue={formik.setFieldValue}
              />
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              size="small"
              disabled={!stripe || !formik.isValid || formik.isSubmitting}
            >
              Pay
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
