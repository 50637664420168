import React from 'react';
import logoSm from '@assets/img/logo-sm.png';

export const CoreLoader = () => {
  return (
    <div className="coreLayout-wrapper">
      <img src={logoSm} alt="logosm" className="coreLayout-logoImg" />
    </div>
  );
};
