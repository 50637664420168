import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { green_b, red_b } from '@theme/customTheme';

export const StyledIconButton = withStyles({
  root: {
    width: ({ wid }) => wid || '56px',
    height: ({ hei }) => hei || '56px',
    background: red_b,
    borderRadius: ({ bordrad }) => bordrad || '16px',
    '&:hover': {
      backgroundColor: red_b,
    },
    padding: ({ padd }) => padd,
  },
  disabled: {
    pointerEvents: 'none',
  },
  sizeSmall: {
    width: '40px',
    height: '40px',
  },
  colorPrimary: {
    background: green_b,
    '&:hover': {
      backgroundColor: '#659818',
    },
  },
  colorSecondary: {
    background: '#F2F2F2',
    borderRadius: '8px',
    color: '#F2F2F2',
    '&:hover': {
      backgroundColor: 'white',
      color: green_b,
      boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.08), 0px 0px 8px rgba(0, 0, 0, 0.05)',
      '& .MuiSvgIcon-root': {
        '& path': {
          stroke: green_b,
        },
      },
    },
  },
})(IconButton);
