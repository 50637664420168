import { MARK_TYPE } from './ExtendedInputLabel';

export const getMarkType = ({ error, visible, value }) => {
  if (visible) {
    return error || value === '' || (Array.isArray(value) && !value[0]) || value === undefined
      ? MARK_TYPE.ERROR
      : MARK_TYPE.SUCCESS;
  }
  return null;
};
