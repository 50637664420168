/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { CabinetHeader } from 'src/components/CabinetHeader/CabinetHeader';
import { isMobile } from '@utils/mobileDetect';

import { useFetch } from 'src/hooks/useFetch';
import { ClaimCompany } from 'src/api/requests';

import { MyCompaniesProgress } from 'src/components/userProgress/MyCompaniesProgress';
import { PageLinkCard } from 'src/components/PageLinkCard/PageLinkCard';
import { ProfileInfo } from '../MyProfile/ProfileInfo/ProfileInfo';
import { SearchCompanies } from '../MyProfile/SearchCompanies/SearchCompanies';
import { CompaniesList } from '../MyProfile/CompaniesList/CompaniesList';
import { MyCompanyUpdateForm } from '../MyCompanyUpdateForm/MyCompanyUpdateForm';
import { useStyles } from '../styles';

const achievementsElement = (
  <div>
    <span>Achievements </span>
  </div>
);
const subscriptions = (
  <div>
    <span>Manage Subscription Plan </span>
  </div>
);
const SubscriptionPlan = (
  <div>
    <span>Manage Subscription Plan </span>
  </div>
);

export const ClaimedCompanies = () => {
  const classes = useStyles();

  const [{ response, isLoading, error }, doFetch] = useFetch(ClaimCompany.getAll);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const triggerUpdateListData = () => {
    setTriggerUpdate(prev => !prev);
  };
  useEffect(() => {
    doFetch();
  }, [triggerUpdate]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <SearchCompanies onTriggerUpdateListData={triggerUpdateListData} />
              </Grid>

              <Grid item xs={12}>
                <MyCompaniesProgress value={response && response.progress_of_claim} />
              </Grid>

              <Grid item xs={12}>
                <Box className={classes.companiesListBox}>
                  <CompaniesList
                    error={error}
                    response={response}
                    isLoading={isLoading}
                    onTriggerUpdateListData={triggerUpdateListData}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const MyCompanyUpdateFormView = ({ match }) => {
  const [{ response, isLoading, error }, doFetch] = useFetch(ClaimCompany.getCollections, true);

  useEffect(() => {
    doFetch(match.params.claim_id);
  }, []);

  const mobileHeader = (
    <div>
      <CabinetHeader addBtn={false} backBtn="profile-info" title={response && response.company_name} />
    </div>
  );

  return (
    <>
      <Grid container spacing={5}>
        {!isMobile && (
          <Grid item xs={12}>
            <CabinetHeader
              addBtn={false}
              backBtn="profile-info"
              title={response && response.company_name}
              tooltipText="The information you enter here will help you resolve the discrepancies that give your customers excuses not to pay you."
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <MyCompanyUpdateForm collectionsData={response} isLoading={isLoading} />
        </Grid>
      </Grid>
    </>
  );
};

export default MyCompanyUpdateFormView;
