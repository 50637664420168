import React from 'react';

import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import bannerImage from '@assets/home/banner-image.jpeg';

import { useStyles } from './styles';

export const Banner = () => {
  const classes = useStyles();

  const history = useHistory();

  const handleGetStarted = () => history.push('/account/my-customers');

  return (
    <Grid container alignItems="center" className={classes.container}>
      <Grid item xs={12} sm={5} className={classes.leftColumn}>
        <Typography variant="h1">Get paid on time</Typography>
        <Box mt={2.5} mb={6}>
          <Typography style={{ fontSize: 20 }}>
            Take control of collections to solve your cash
            <br />
            flow problems
          </Typography>
        </Box>
        <Button variant="contained" color="primary" className={classes.button} onClick={handleGetStarted}>
          Get started
        </Button>
      </Grid>
      <Grid item xs={12} sm={7}>
        <img src={bannerImage} alt="banner" style={{ display: 'block' }} />
      </Grid>
    </Grid>
  );
};
