import { withStyles } from '@material-ui/core/styles';
import { MenuList } from '@material-ui/core';

export const CustomMenuList = withStyles({
  root: {
    background: 'white',
    padding: '8px',
    borderRadius: '8px',
    boxShadow: '4px 16px 32px rgba(0, 0, 0, 0.08)',
  },
})(MenuList);
