import { makeStyles } from '@material-ui/core/styles';
import { green_b, grey_shades } from '@theme/customTheme';

export const useStyles = makeStyles(theme => ({
  paper: {
    border: `1px dashed ${green_b}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px',
    cursor: 'pointer',
  },
  paperClipBox: {
    marginRight: '17px',
  },
  text: {
    color: grey_shades,
    fontSize: '16px',
    lineHeight: '150%',
  },
  textSpan: {
    color: green_b,
    fontWeight: 600,
  },
  fileList: {
    listStyle: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    margin: 0,
  },
  fileListMargin: {
    marginTop: '16px',
  },
  fileListItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '16px',
    marginRight: '16px',
  },
  fileIconBox: {
    marginRight: '20px',
  },
  timesIconBox: {
    marginLeft: '20px',
  },
}));
