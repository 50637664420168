import { setAuthToken } from '@utils/setAuthToken';
import { showSuccessNotification } from '@components/common/Notifications';

import { t } from '../actionTypes';
import { history } from '../../App';

export const setCurrentUserTokens = tokens => ({ type: t.SET_CURRENT_USER_TOKENS, payload: tokens });

export const setCurrentUserData = user => ({ type: t.SET_CURRENT_USER_DATA, payload: user });

export const setCurrentUserDataMsStatus = status => ({ type: t.SET_CURRENT_USER_MS_STATUS, payload: status });

export const loguot = () => {
  history.push('/');
  setAuthToken(false);
  localStorage.removeItem('jwt');
  showSuccessNotification('Successfully logged out');
  return { type: t.LOGUOT };
};
