/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { CustomHelperText } from '@components/common/CustomHelperText';
import clsx from 'classnames';
import { CardElement } from '@stripe/react-stripe-js';
import cardIcons from '@assets/img/credit-card-icons.png';
import { grey_dark, grey_medium, red_b } from '@theme/customTheme';

const useStyles = makeStyles(theme => ({
  componentWrapper: {
    width: '100%',
  },
  label: {
    textTransform: 'uppercase',
    fontWeight: '600',
    color: grey_dark,
  },
  textField: {
    height: "30px",
    fontSize: 18,
    paddingTop: "8px",
  },
  textFieldError: {
    border: `1px solid ${red_b}`,
  },
  isReadyStripeTextField: {
    display: 'none',
  },
  cardIcons:{
    width: "150px",
    marginBottom: "1px",
    marginLeft: "-2px",
  }
}));

export const CustomStripeTextField = ({ name, type, label, error, helperText, onSetFieldTouched, onSetFieldValue }) => {
  const classes = useStyles();

  const [isReadyField, setReadyStatus] = useState(false);

  const options = {
    hidePostalCode: true,
    classes: {
      base: clsx(classes.textField, { [classes.textFieldError]: error }),
    },
  };

  const handleChangeStripeTextField = event => {
    onSetFieldValue(name, event.complete);
    if (event.error) {
      onSetFieldTouched(name, true);
    }
  };

  const handleBlurStripeTextField = () => {
    onSetFieldTouched(name, true);
  };

  const handleReadyStripeTextField = () => {
    setReadyStatus(true);
  };

  return (
    <div className={classes.componentWrapper}>
      <div className={classes.label}>{label}</div>
      <img className={classes.cardIcons} src={cardIcons} alt="card icons"/>
      <div className={clsx({ [classes.isReadyStripeTextField]: isReadyField })}>
        <Skeleton animation="wave" height="42px" width="100%" />
      </div>
      <div className={clsx({ [classes.isReadyStripeTextField]: !isReadyField })}>
      <b>Payment details</b>
        <CardElement
          options={options}
          onChange={handleChangeStripeTextField}
          onBlur={handleBlurStripeTextField}
          onReady={handleReadyStripeTextField}
        />
      </div>
      {error && <CustomHelperText>{helperText}</CustomHelperText>}
    </div>
  );
};
