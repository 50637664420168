/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Line } from '@components/common/Line';
import { Box, Grid, Typography } from '@material-ui/core';
import { Container } from '@components/common/Container';
import academyImg from '@assets/home/academy/academy.png';
import udemyImg from '@assets/home/academy/udemy.png';
import academyMobImg from '@assets/home/academy/academy-mobile.png';
import udemyMobImg from '@assets/home/academy/udemy-mobile.png';
import emailBlockImg from '@assets/home/academy/banner.png';
import { ReactComponent as ArrowRightIcon } from '@assets/svg/icons/arrow-right.svg';
import { isMobile } from '@utils/mobileDetect';
import { CenteredBox } from '@components/common/CenteredBox';

import { useStyles } from './styles';
import { SubscribeForm } from './SubscribeForm/SubscribeForm';

export const AcademySection = () => {
  const classes = useStyles();
  return (
    <Box className={classes.academySection} id="academySection">
      <Box className={classes.header}>
        <Container>
          <Grid container spacing={3} className={classes.gridContainer}>
            <Grid item xs={12}>
              <Typography variant="h3">RIABU Academy</Typography>
            </Grid>
            <Grid item xs={12}>
              <Line />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className={classes.content}>
        <Grid container spacing={isMobile ? 0 : 2} className={classes.gridContainer}>
          <Grid item xs={12}>
            <Grid container spacing={isMobile ? 0 : 2} alignItems="stretch">
              <Grid item xs={12} md={6} className={classes.gridMobileItem}>
                <Box className={classes.baseItem}>
                  {isMobile ? (
                    <Box>
                      <Box className={classes.titleBox}>
                        <Typography variant="h6">Academy</Typography>
                      </Box>
                      <Box className={classes.imageBox}>
                        <img className={classes.image} src={academyMobImg} alt="" />
                      </Box>
                      <Box className={classes.descriptionBox}>
                        <Typography variant="body2">
                          The RIABU Academy is founded in decades of market-proven experience and techniques used by
                          Multinational Companies. Find out what you can do to ensure your customers pay you on time.
                        </Typography>
                      </Box>
                      <a href="https://vimeo.com/channels/riabu/" target="_blank" className={classes.linkBox}>
                        <Typography className={classes.linkText}>Watch</Typography>
                        <CenteredBox className={classes.ArrowIconBox}>
                          <ArrowRightIcon />
                        </CenteredBox>
                      </a>
                    </Box>
                  ) : (
                    <Grid container spacing={5}>
                      <Grid item xs>
                        <Box className={classes.contentBox}>
                          <Box className={classes.textBox}>
                            <Box className={classes.titleBox}>
                              <Typography variant="h6">Academy</Typography>
                            </Box>
                            <Box className={classes.descriptionBox}>
                              <Typography variant="body2">
                                The RIABU Academy is founded in decades of market-proven experience and techniques used
                                by Multinational Companies. Find out what you can do to ensure your customers pay you on
                                time.
                              </Typography>
                            </Box>
                          </Box>

                          <a href="https://vimeo.com/channels/riabu/" target="_blank" className={classes.linkBox}>
                            <Typography className={classes.linkText}>Watch</Typography>
                            <CenteredBox className={classes.ArrowIconBox}>
                              <ArrowRightIcon />
                            </CenteredBox>
                          </a>
                        </Box>
                      </Grid>
                      <Grid item xs="auto">
                        <Box className={classes.imageBox}>
                          <img className={classes.image} src={academyImg} alt="" />
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={6} className={classes.gridMobileItem}>
                <Box className={classes.baseItem}>
                  {isMobile ? (
                    <Box>
                      <Box className={classes.titleBox}>
                        <Typography variant="h6">Udemy course</Typography>
                      </Box>
                      <Box className={classes.imageBox}>
                        <img className={classes.image} src={udemyMobImg} alt="" />
                      </Box>
                      <Box className={classes.descriptionBox}>
                        <Typography variant="body2">
                          The RIABU Academy is founded in decades of market-proven experience and techniques used by
                          Multinational Companies. Find out what you can do to ensure your customers pay you on time.
                        </Typography>
                      </Box>
                      <a
                        href="https://www.udemy.com/course/how-to-get-your-invoices-paid-on-time-every-time/"
                        target="_blank"
                        className={classes.linkBox}
                      >
                        <Typography className={classes.linkText}>Watch</Typography>
                        <CenteredBox className={classes.ArrowIconBox}>
                          <ArrowRightIcon />
                        </CenteredBox>
                      </a>
                    </Box>
                  ) : (
                    <Grid container spacing={5}>
                      <Grid item xs>
                        <Box className={classes.contentBox}>
                          <Box className={classes.textBox}>
                            <Box className={classes.titleBox}>
                              <Typography variant="h6">Academy</Typography>
                            </Box>
                            <Box className={classes.descriptionBox}>
                              <Typography variant="body2">
                                Too often, you are left waiting, and you end up having to chase your money long after
                                the payment is due. In this course, you will learn how to avoid invoices ever becoming
                                overdue.
                              </Typography>
                            </Box>
                          </Box>

                          <a
                            href="https://www.udemy.com/course/how-to-get-your-invoices-paid-on-time-every-time/"
                            target="_blank"
                            className={classes.linkBox}
                          >
                            <Typography className={classes.linkText}>Learn</Typography>
                            <CenteredBox className={classes.ArrowIconBox}>
                              <ArrowRightIcon />
                            </CenteredBox>
                          </a>
                        </Box>
                      </Grid>
                      <Grid item xs="auto">
                        <Box className={classes.imageBox}>
                          <img className={classes.image} src={udemyImg} alt="" />
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box className={classes.baseItem}>
              <Grid container className={classes.gridContainer}>
                <Grid item xs={12} md>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md="auto">
                      <Box className={classes.academyImgBox}>
                        <img className={classes.academyImgBanner} src={emailBlockImg} alt="" />
                      </Box>
                    </Grid>

                    <Grid item xs={12} md>
                      <Grid container spacing={isMobile ? 2 : 3}>
                        <Grid item xs={12}>
                          <Typography className={classes.subscribeBlockTitle}>Get our Email course</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.subscribeBlockSubTitle}>
                            Frustrated with late payments? Subscribe to our weekly email programme and see a significant
                            improvement in cash flow in fewer than 100 days.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs md={3}>
                  <Box className={classes.subscribeFormBox}>
                    <SubscribeForm />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
