import { makeStyles } from '@material-ui/core/styles';
import { grey_dark } from '@theme/customTheme';

export const useStyles = makeStyles(theme => ({
  gridRoot: {
    flexGrow: 1,
  },
  gridTitle: {
    height: '100%',
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width:480px)': {
      flexDirection: 'column',
    },
  },
  helpIconBox: {
    cursor: 'pointer',
  },
  titleCompany: {
    fontFamily: 'Open Sans',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: grey_dark,
    marginLeft: '10px',
    '@media (max-width:480px)': {
      fontSize: '12px',
      lineHeight: '16px',
      display: 'inline',
      width: '140px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  sectionTitle: {
    lineHeight: '24px',
    fontWeight: 600,
    '@media (max-width:480px)': {
      lineHeight: '16px',
    },
  },
  gridContainerHeader: {
    position: 'relative',
  },
  gridBackBtn: {
    '@media (max-width:480px)': {
      position: 'absolute',
      top: '16px',
      left: '-16px',
      transform: 'translateY(-4px)',
    },
  },
  backBtn: {
    '@media (max-width:480px)': {
      width: '32px',
      height: '32px',
      borderRadius: '8px',
      position: 'absolute',
    },
  },
  ArrowLeftIcon: {
    '@media (max-width:480px)': {
      width: '16px',
      height: '16px',
      position: 'absolute',
    },
  },
  tooltipPaperText: {
    width: '250px',
    padding: '0px 10px',
    fontSize: '12px',
    lineHeight: '16px',
  },
}));
