import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BasePieChart, IBaseChartProps } from '../common/BasePieChart';

// @ts-ignore
export const useStyles = makeStyles(() => ({
  card: {
    flexWrap: 'noWrap',
  },
  card__title: {
    fontWeight: 700,
  },
}));

export interface IPieTextStatistics extends IBaseChartProps {
  title: string | React.ElementType;
  subtitle: string | React.ElementType;
}

export const BaseProgress: React.FC<IPieTextStatistics> = props => {
  const { totalValue, value, labelType, title, subtitle } = props;
  const classes = useStyles();

  return (
    <Grid container spacing={2} direction="row" alignItems="center" className={classes.card}>
      <Grid item>
        <BasePieChart totalValue={totalValue} value={value || 0} labelType={labelType} />
      </Grid>
      <Grid item>
        <div className={classes.card__title}>{title}</div>
        <div>{subtitle}</div>
      </Grid>
    </Grid>
  );
};
