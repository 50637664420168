import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AssessmentModal from 'src/pages/Accreditation/Rules/components/AssessmentModal';
import { useStyles } from './styles';

export const Assesment = ({adStyle = true}) => {
  const classes = useStyles();
  const [isQuizModalVisible, handleToggleQuizModalVisibility] = useState(false);

  return (
    <div className={classes.container} style={adStyle ? {paddingTop: "15%"} : {}}>
      <Typography style={{ color: '#A8ADB2' }} align="center">
        Assessment
      </Typography>
      <Box mt={3.5} mb={3.5}>
        <Typography className={classes.title} align="center">
          What am I doing wrong?
        </Typography>
      </Box>
      <Box mb={5}>
        <Typography style={{ color: '#5E6369' }} align="center">
          Take the assessment to confirm you have eliminated all possible loopholes your
          <br />
          customer can exploit to not pay you on time.
        </Typography>
      </Box>
      <Grid container justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          style={{ width: 240 }}
          onClick={() => handleToggleQuizModalVisibility(true)}
          >
          Take our assessment
        </Button>
      </Grid>
      <br />
      <AssessmentModal
        handleToggleQuizModalVisibility={handleToggleQuizModalVisibility}
        isQuizModalVisible={isQuizModalVisible}
      />
    </div>
  );
};
