import { makeStyles } from '@material-ui/core/styles';
import { black_main, grey_dark } from '@theme/customTheme';

export const useStyles = makeStyles(theme => ({
  root: {},
  gridRoot: {
    flexGrow: 1,
  },
  gridSearch: {
    flexGrow: 1,
  },
  autocompleteWrapper: {
    flexGrow: 1,
  },
  searchPaper: {
    borderRadius: '16px',
    padding: '12px 18px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  searchIcon: {
    marginRight: '18px',
  },
  searchAutocomplete: {
    '& input': {
      border: 'none',
      outline: 'none',
      boxSizing: 'border-box',
      color: black_main,
      fontSize: '16px',
      '&::placeholder': {
        fontSize: '16px',
        lineHeight: '24px',
        color: grey_dark,
        letterSpacing: '0.02em',
      },
    },
    '& .MuiAutocomplete-paper': {
      position: 'absolute',
      top: '0px',
      left: '0px',
    },
    '& .MuiList-root': {
      padding: '0px',
    },
  },
  sectionHeaderText: {
    lineHeight: '32px',
    fontWeight: 600,
  },
  searchAutocompleteInput: {
    outline: 'none',
    border: 'none',
    color: black_main,
    fontSize: '14px',
    lineHeight: '24px',
    width: '100%',
    '&::placeholder': {
      fontSize: '14px',
      color: grey_dark,
      lineHeight: '24px',
      textTransform: 'capitalize',
      opacity: 1,
    },
  },
  customOption: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  optionHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '16px',
  },
  optionHeadingTitle: {
    flexGrow: 1,
    display: 'block',
    lineHeight: '24px',
    color: '#000000',
  },
  optionHeadingBtn: {
    width: '120px',
  },
  optionInfoListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '17px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  optionInfoTitle: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 600,
    color: grey_dark,
  },
  optionInfoValue: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 600,
    color: black_main,
  },
}));
