import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { useFetch } from 'src/hooks/useFetch';
import { BaseDialog } from 'src/components/common/dialog/BaseDialog';
import { getMultiAccessEmployeesList, deleteEmployeeMultiAccess } from 'src/api/requests/multiAccess';
import { useDialogVisibility } from 'src/hooks/useDialogVisibility';
import { AddEmployeeFormContainer } from './AddEmployee/AddEmployeeFormContainer';
import { EmployeeTable } from './EmployeeTable/EmployeeTable';
import { showErrorNotification, showSuccessNotification } from '../../../../components/common/Notifications';
import { LoadingBox } from '../../MyProfile/Achievements/LoadingBox';
import { PaginatedContainer } from '../../MyProfile/Achievements/PaginatedContainer';

interface IEmployeesProps {
  companyId: number;
}

interface IConfirmDialog {
  onConfirm: any;
  onClose: any;
  isOpen: boolean;
}

const ConfirmDialog: React.FC<IConfirmDialog> = props => {
  const { onConfirm, onClose, isOpen } = props;

  return (
    <BaseDialog title="Confirmation" open={isOpen} onClose={onClose}>
      <Grid container spacing={2} xs={12} justify="center">
        <Grid item>
          <Typography variant="body2">Are you sure want to remove this access?</Typography>
        </Grid>
        <Grid item>
          <Button color="primary" variant="outlined" onClick={onConfirm}>
            Confirm
          </Button>
        </Grid>
      </Grid>
    </BaseDialog>
  );
};

// interface IEmployeesResponse {
//   status: boolean;
//   data: Array<any>;
//   message?: string;
// }

export const Employees: React.FC<IEmployeesProps> = props => {
  const { companyId } = props;

  const [{ response, error, isLoading }, doFetch, setResponse] = useFetch(getMultiAccessEmployeesList, true, {
    isDataUnpacked: false,
  });

  const {
    isOpened: confirmDialogVis,
    handleClose: closeConfirmDialog,
    handleOpen: openConfirmDialog,
  } = useDialogVisibility();

  const [deleteAccessId, setDeleteAccessId] = useState<null | number>();
  useEffect(() => {
    if (deleteAccessId) {
      openConfirmDialog();
    } else {
      closeConfirmDialog();
    }
  }, [deleteAccessId, closeConfirmDialog, openConfirmDialog]);

  useEffect(() => {
    doFetch(companyId);
  }, [companyId, doFetch]);

  useEffect(() => {
    if (error && error.response) {
      showErrorNotification(error.response.data.message, 3000);
    }
  }, [error]);

  const onSubmitDeleteEmployee = async () => {
    const responseDeleting: any = await deleteEmployeeMultiAccess(deleteAccessId);
    if (responseDeleting.success) {
      setResponse((res: any) => ({
        ...res,
        data: res.data.filter((item: any) => item.id !== deleteAccessId),
      }));
      setDeleteAccessId(null);
      showSuccessNotification('Access has been deleted successfully', 3000);
    }
  };
  const handleAddEmployee = (employee: any) => {
    setResponse((res: any) => ({
      ...res,
      data: [employee, ...res.data],
    }));
  };

  return (
    <>
      <AddEmployeeFormContainer onAddEmployee={handleAddEmployee} />
      <LoadingBox loading={isLoading}>
        <PaginatedContainer onChange={() => {}} defaultPage={1} count={1}>
          <EmployeeTable data={(response && response.data) || []} onDeleteAccess={setDeleteAccessId} />
        </PaginatedContainer>
      </LoadingBox>

      <ConfirmDialog
        isOpen={confirmDialogVis}
        onClose={() => setDeleteAccessId(null)}
        onConfirm={onSubmitDeleteEmployee}
      />
    </>
  );
};
