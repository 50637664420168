import React from 'react';
import { CircularProgress } from '@material-ui/core';

import { useStyles } from './styles';

export const GlobalLoader = () => {
  const classes = useStyles();

  return (
    <div className={classes.loaderBox}>
      <CircularProgress />
    </div>
  );
};
