import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = () => ({
  rootTitle: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButton: {
    color: 'black',
  },
});

const BaseDialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.rootTitle} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export function BaseDialog(props) {
  const { open, onClose, title, maxWidth, fullWidth, children } = props;
  return (
    <Dialog open={open} maxWidth={maxWidth} fullWidth={fullWidth}>
      <BaseDialogTitle onClose={onClose}>{title}</BaseDialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
