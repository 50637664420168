import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: 60,
    paddingBottom: 60,

    [theme.breakpoints.down('sm')]: {
      paddingTop: 30,
      paddingBottom: 30,
    },
  },

  content: {
    backgroundColor: '#F9FBFD',
    padding: 40,
    borderRadius: 16,

    [theme.breakpoints.down('sm')]: {
      padding: 24,
    },
  },

  title: {
    color: '#040C14',
    fontSize: 40,
    fontWeight: 'bold',
    position: 'relative',

    '&::before': {
      position: 'absolute',
      content: "' '",
      width: 40,
      height: 2,
      backgroundColor: '#000000',
      bottom: -32,
      left: 0,
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  resultsContainer: {
    display: "flex",
    justifyContent: "center",
    minWidth: "100%",
    minHeight: "60%",
    flexDirection: "column",
    borderRadius: "10px",
    paddingLeft: "10%",
    backgroundColor: '#fff',
    boxShadow: "0px 0px 10px 0px #0000001a",
    "@media (max-width: 1279px)": {
      height: "35rem",
    }
  },
  resultsMain: {
    marginTop: "50px",
    marginBottom: "70px",
    "& sup": {
      fontSize: "35px",
    },
    "& p": {
      "@media (max-width: 460px)": {
        fontSize: "45px !important",
      }
    }
  },
  resultItems: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "@media (max-width: 460px)": {
      justifyContent: "space-around",
    },
    "& > div": {
      margin: "2%",
      width: "28%",
      "& > p": {
        "@media (max-width: 460px)": {
          fontSize: "20px !important",
        }
      },
      "@media (max-width: 460px)": {
        width: "45%",
        margin: "5% 0px",
      }
    },
    "& sup": {
      fontSize: "10px",
    }
  },
  CalculateBtn: {
    width: "220px",
    height: "55px",
    position: "relative",
    left: "135%",
    bottom: "9rem",
    boxShadow: "0px 4px 8px 1px #0000004f",
    "@media (max-width: 1279px)": {
      position: "relative",
      left: "19%",
      top: "45.4rem",
    },
    "@media (max-width: 460px)": {
      width: "150px",
      height: "40px",
      fontSize: "13px",
      left: "25%"
    }
  },
  fontDollars: {
    margin: "10px 0px",
    fontSize: "35px",
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '600',
    "& > sup": {
      fontSize: "17px"
    }
  },

  slider: {
    "& .MuiSlider-markLabel": {
      color: '#b3c5a0',
      top: 16,
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 400,
    },
    "& .MuiSlider-markLabel[data-index='0']": {
      marginLeft: 28,
    },
    "& .MuiSlider-markLabel[data-index='1']": {
      marginLeft: -35,
    },
    "& .MuiSlider-thumb": {
      width: "16px",
      height: "16px",
      marginTop: "-7px",
      border: "2px solid #89CC21",
      backgroundColor: "#fff",
    }
  }
}));
