import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: 60,
    paddingBottom: 60,

    [theme.breakpoints.down('sm')]: {
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  title: {
    color: '#77CC21',
    fontSize: 40,
    fontWeight: 'bold',
    lineHeight: 1.2,

    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
}));
