import React from 'react';
import { TextField, Box } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { grey_light, black_main, red_b } from 'src/theme/customTheme';
import { ExtendedInputLabel } from './label/ExtendedInputLabel';
import { getMarkType } from './label/getMarkType';

// if helperText is passed - error === true

const CustomTextFieldComponent = (props: any) => {
  const {
    helperTextPosAbsl = false,
    variant = 'outlined',
    multiline = false,
    disabled = false,
    rowsMax = false,
    label = '',
    error = false,
    nottu = 'false',
    rows = false,
    placeholder,
    helperText = '',
    onChange,
    onFocus = () => {},
    onBlur,
    value,
    name,
    type = 'text',
    helperErrorText,
    helperErrorTextMark = '',
    isValMarkVisible = false,
    lowerCasePlaceholder = false,
    inputRef,
    ...otherProps
  } = props;
  const markType = getMarkType({ error: helperErrorText || helperErrorTextMark, visible: isValMarkVisible, value });

  return (
    <Box>
      {label && <ExtendedInputLabel nottu={nottu} label={label} markType={markType} />}
      <StyledTextField
        disabled={disabled}
        name={name}
        type={type}
        value={value}
        error={error || Boolean(helperErrorText)}
        onBlur={onBlur}
        variant={variant}
        onFocus={onFocus}
        onChange={onChange}
        multiline={multiline}
        rows={rows.toString()}
        helperText={helperText || helperErrorText}
        placeholder={placeholder}
        rowsMax={rowsMax.toString()}
        helpertextposabsl={helperTextPosAbsl.toString()}
        lowerCasePlaceholder={lowerCasePlaceholder}
        InputProps={{
          disableUnderline: variant === 'filled',
        }}
        inputRef={inputRef}
        {...otherProps}
      />
    </Box>
  );
};

const StyledTextField = styled(TextField)({
  '& .MuiFilledInput-root': {
    height: 'auto',
    borderRadius: '4px',
    background: grey_light,
  },
  '& .MuiFilledInput-input': {
    padding: '8px 16px',
    fontSize: '14px',
    lineHeight: '24px',
    color: black_main,
  },
  '& .MuiFilledInput-multiline': {
    padding: '0',
  },
  '& .MuiFilledInput-inputMultiline': {
    padding: '12px 16px',
  },
  '& .MuiOutlinedInput-multiline': {
    padding: '12px 16px',
  },
  '& .MuiOutlinedInput-input': {
    '&::placeholder': {
      // @ts-ignore
      textTransform: ({ lowerCasePlaceholder }) => (lowerCasePlaceholder ? 'lowercase' : 'capitalize'),
    },
  },
  // @ts-ignore
  '& .MuiFormHelperText-root': {
    transition: '.3s',
    margin: '0 !important',
    marginTop: '2px !important',
    fontSize: '10px',
    lineHeight: '13px',
    color: red_b,
    // @ts-ignore
    position: ({ helpertextposabsl }) => (helpertextposabsl === 'true' ? 'relative' : 'absolute'),
    // @ts-ignore
    top: ({ helpertextposabsl }) => helpertextposabsl !== 'true' && '100%',
  },
  '& .Mui-disabled': {
    background: grey_light,
    cursor: 'not-allowed',
  },
});

export const CustomTextField = React.memo(CustomTextFieldComponent);
