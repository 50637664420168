import React from 'react';
import { Line } from '@components/common/Line';
import { Box, Grid, Typography } from '@material-ui/core';
import { CustomAccordion } from './Accordion/CustomAccordion';

import { useStyles } from './styles';

const accordionItems = [
  {
    id: 1,
    title: 'What is RIABU ?',
    description:
      'RIABU helps companies of all sizes – but especially SMEs – be first in line to get paid. We provide you with insights into the past payment conduct of prospects and customers, and how long they are likely to take to pay their invoices, based on the experiences of other suppliers. RIABU also helps you to get paid on time in the future, by giving you access to the RIABU Academy. There you can find expert advice, best practice guides and peer forums to improve your policies and processes and ensure you get paid faster.',
    panel: 'panel1',
  },
  {
    id: 2,
    title: 'How is RIABU different?',
    description:
      'RIABU is an ancient Akkadian word meaning “fair compensation”: we want you to get paid on time. We are advocates for SMEs, who often get a poor deal. Above all, we DO NOT lend money or factor invoices. We want to put our experience at your disposal and help you to get what you are owed, on time, so you are NOT forced to borrow more.',
    panel: 'panel2',
  },
  {
    id: 3,
    title: 'Who should use RIABU?',
    description:
      'RIABU is for companies large and small, but especially SMEs, sole traders, freelancers – anyone who wants to know the likely time frame for getting paid before deciding whether to supply a product or service to a customer, and to know what to do when they find they are being paid late.',
    panel: 'panel3',
  },
  {
    id: 4,
    title: 'How does RIABU work?',
    description:
      'RIABU asks you to give your customers a rating and share their past payment track record. By aggregating this data anonymously, RIABU can tell you when the customer is likely to settle their invoices. By answering a few questions, RIABU can also tell you what you need to do to get paid faster.',
    panel: 'panel4',
  },
  {
    id: 5,
    title: 'What information does RIABU collect?',
    description:
      "By contributing information about your customers' payment conduct, you help other suppliers decide whether to provide products and services to them, and on what terms. To rate a customer, you will be prompted for certain details about the invoices you issued. We DO NOT need to know the amount of the invoice, the identity of your contact person at the company, or itemised details about the products or services you provided. You can provide the payment track record of as many customers and as many invoices as you wish. The more you contribute, the more you help the RIABU community globally, and level the playing field for SMEs. Better still: Connect to Xero or QuickBooks to automatically provide details to the RIABU Community.",
    panel: 'panel5',
  },
  {
    id: 6,
    title: 'How does RIABU use the information I submit?',
    description:
      'Customer payment conduct information which suppliers provide is aggregated anonymously to derive an average time the customer takes to pay their invoices. The information is not shared with third parties, but you can download it and send it to prospective financiers. Please see our terms of use for further details.',
    panel: 'panel6',
  },
  {
    id: 7,
    title: 'What if no one has provided a review of a customer I searched for?',
    description: 'Be the first to provide a review.',
    panel: 'panel7',
  },
  {
    id: 8,
    title: 'How accurate is RIABU data?',
    description:
      'We combine data which users like you provide, with other industry databases to which we have access, both within Singapore and more widely. We aim to be current and accurate for your industry. To get a better result it is useful for you to contribute payment conduct data, and ensure it is entered accurately. We do not check the accuracy, and make no warranty that the payment track record data is correct.',
    panel: 'panel8',
  },
  {
    id: 9,
    title: 'Can I delete my RIABU account, and all the information I provided?',
    description: 'Yes. Just email us at register@riabu.com.',
    panel: 'panel9',
  },
  {
    id: 10,
    title: 'How much does RIABU cost?',
    description:
      'Registration to RIABU is free during beta testing. Some services, such as our Udemy course, is chargeable. Prices are clearly shown when you sign up.',
    panel: 'panel10',
  },
];

export const QuestionsSection = () => {
  const classes = useStyles();

  return (
    <Box className={classes.questionsSectionWrapper}>
      <Box className={classes.questionsSection}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h3">Frequently asked questions</Typography>
              </Grid>
              <Grid item xs={12}>
                <Line />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.accordionBox}>
              <CustomAccordion data={accordionItems} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
