/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { Box, Grid, Button, TextField } from '@material-ui/core';
import { styled } from '@material-ui/styles';

import { showSuccessNotification, showErrorNotification } from '@components/common/Notifications';
import { handleInvalidError } from '@utils/handleInvalidError';

import { useFormik } from 'formik';
import * as yup from 'yup';
import * as validation from '@constants/validation/validation';

import { MountUnmountCaptcha } from '@components/ReCaptcha/MountUnmountCaptcha';
import { ReCaptcha } from '@components/ReCaptcha/ReCaptcha';
import { SubscribeCourse } from '@api/requests';

import { useStyles } from './styles';

export const SubscribeForm = () => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      full_name: '',
      email: '',
      re_captcha: '',
    },
    validationSchema: yup.object().shape({
      full_name: validation.FIRST_NAME,
      email: validation.EMAIL,
    }),
    onSubmit: values => {
      SubscribeCourse.subscribe(values)
        .then(res => {
          if (res.success) {
            if (process.env.NODE_ENV === 'production') {
              window.gtag('event', 'submit', {
                event_category: 'form',
                event_label: 'Subscribe',
              });
            }
            showSuccessNotification(res.data, 3000);
            formik.setSubmitting(false);
          }
        })
        .catch(err => {
          formik.setSubmitting(false);

          const { status } = err.response;
          const { message } = err.response.data;
          if (status === 422) {
            showErrorNotification(message);
            handleInvalidError(formik.setErrors)(err);
          } else {
            showErrorNotification(err.DEFAULT);
          }
        });
    },
  });

  const handleGenerateCaptchaToken = async execute => {
    const token = await execute();
    formik.setFieldValue('re_captcha', token, false);
  };

  return (
    <Box className={classes.subscribeForm}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTextField
              type="text"
              name="full_name"
              placeholder="full name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.full_name}
              InputProps={{ disableUnderline: true }}
              helperText={formik.touched.full_name && formik.errors.full_name}
              error={Boolean(formik.touched.full_name && formik.errors.full_name)}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              type="email"
              name="email"
              placeholder="email"
              onBlur={formik.handleBlur}
              value={formik.values.email}
              onChange={formik.handleChange}
              InputProps={{ disableUnderline: true }}
              helperText={formik.touched.email && formik.errors.email}
              error={Boolean(formik.touched.email && formik.errors.email)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              size="large"
              variant="contained"
              type="submit"
              disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting}
            >
              Subscribe
            </Button>
          </Grid>
          <MountUnmountCaptcha>
            <ReCaptcha action="test" onHandleGenerateCaptchaToken={handleGenerateCaptchaToken} />
          </MountUnmountCaptcha>
        </Grid>
      </form>
    </Box>
  );
};

const CustomTextField = styled(TextField)({
  background: '#FFFFFF',
  borderRadius: '4px',
  padding: '16px',
  '& .MuiInputBase-input': {
    padding: 0,
    fontSize: '14px',
    lineHeight: '24px',
    fontFamily: 'Open Sans',
    '&::placeholder': {
      textTransform: 'uppercase',
    },
  },
});
