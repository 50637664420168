import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#F9FBFD',
    marginTop: 40,
  },

  leftColumn: {
    padding: 40,

    [theme.breakpoints.down('sm')]: {
      order: 2,
      margin: 0,
      padding: 24,
    },
  },

  button: {
    width: 240,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
