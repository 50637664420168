import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  notificationsWrapper: {
    position: 'sticky',
    top: 0,
    zIndex: 999,
  },
  notificationLineBox: {
    background: 'red',
    maxHeight: '0px',
    transition: 'max-height 0.5s ease-out',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  textBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  notificationLineBoxActive: {
    maxHeight: '30px',
    transition: 'max-height 0.5s ease-out',
  },
  warning: {
    background: '#FFC107',
    color: 'white',
  },
  danger: {
    background: '#DC3545',
    color: 'white',
  },
  info: {
    background: '#17A2B8',
    color: 'white',
  },
  success: { background: '#28A745' },
  hidden: {
    display: 'none',
  },
  timesIconBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 10,
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
  },
  notificationText: {
    marginRight: '10px',
  },
  notificationLink: {
    color: 'white',
  },
}));
