/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useRef, useEffect } from 'react';
import { Grid, Typography, Button, Box, Popper, RootRef, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import { isMobile } from '@utils/mobileDetect';
import { ReactComponent as TimesIcon } from '@assets/svg/icons/times.svg';
import { showSuccessNotification, showErrorNotification, showWarnNotification } from '@components/common/Notifications';

import { ReactComponent as SearchIcon } from '@assets/svg/icons/search.svg';
import { StyledIconButton } from '@components/common/StyledIconButton';
import { CenteredBox } from '@components/common/CenteredBox';
import { Company, ClaimCompany } from '@api/requests';
import { useFetch } from '@hooks/useFetch';

import { useDebounce } from 'use-debounce';

import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';

export const SearchCompanies = ({ onTriggerUpdateListData, homeSearch = false }) => {
  const classes = useStyles();
  const navigate = useHistory()
  const [openSearch, setOpenSearch] = useState(false);

  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyQuery, setCompanyQuery] = useState(undefined);

  const [companyRes, setCompanyRes] = useState([]);
  const [companyIsLoading, setCompanyIsLoading] = useState(false);
  const [companyIsError, setCompanyIsError] = useState(false);
  const [selectCompany, setSelectCompany] = useState(null);

  const [debouncedText] = useDebounce(companyQuery, 500);

  const searchRef = useRef(null);

  const companyFindReq = async (query, axiosSource) => {
    let res;
    setCompanyIsLoading(true);
    try {
      res = await Company.find({ query }, axiosSource.token);
      setCompanyRes(res.data);
    } catch (e) {
      if (axios.isCancel(axiosSource)) {
        return;
      }
      setCompanyRes([]);
      setCompanyIsLoading(false);
      setCompanyIsError(true);
    }
    setCompanyIsLoading(false);
  };

  const handleClaimCompany = () => {
    setOpenSearch(prev => !prev);
    setCompanyQuery(undefined);
  };

  const handleClaimCompanyRequest = async id => {
    try {
      const res = await ClaimCompany.create({ company_id: id });
      showSuccessNotification(res.message);
      onTriggerUpdateListData();
    } catch (e) {
      showErrorNotification(e.response.data.message);
    }
  };

  const handleSearchCompany = e => {
    if (e) {
      const query = e.currentTarget.value;
      if (query === 0 || query === undefined || query === null) return;
      if (query.trim().length < 3) {
        showWarnNotification('The query must be at least 3 characters', 2000);
      } else {
        setCompanyQuery(query);
      }
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (debouncedText) {
      companyFindReq(debouncedText, source);
    } else {
      setCompanyRes([]);
    }
    return () => {
      source.cancel('Canceled because of component unmounted or debounce Text changed');
    };
  }, [debouncedText]);

  useEffect(() => {
    if (selectCompany) {
      navigate.push(`account/company/info/${selectCompany.id}`);
    }
    return setSelectCompany(null);
  }, [navigate, selectCompany]);

  useEffect(() => {
    if (companyRes && companyRes.length) {
      setCompanyOptions(companyRes);
    }
  }, [companyRes]);
  const searchComponent = <Paper ref={searchRef} elevation={0} className={classes.searchPaper} style={{ position: 'relative' }}>
    <CenteredBox className={classes.searchIcon}>
      <SearchIcon />
    </CenteredBox>
    <Box className={classes.autocompleteWrapper}>
      <Autocomplete
        inputValue={companyQuery}
        options={companyOptions}
        loading={companyIsLoading}
        onChange={(_, newValue) => {
          return homeSearch ? setSelectCompany(newValue) : "";
        }}
        getOptionLabel={option => option.name}
        renderOption={option => {
          const {
            id,
            name,
            uen,
            comment,
            currency,
            uen_status,
            company_url,
            procurement_policies,
            standard_credit_terms,
          } = option;
          return (
            <div className={classes.customOption}>
              <div className={classes.optionHeading}>
                <Typography variant="h6" className={classes.optionHeadingTitle}>
                  {name}
                </Typography>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.optionHeadingBtn}
                  onClick={() => handleClaimCompanyRequest(id)}
                >
                  Claim
                </Button>
              </div>
              {homeSearch ? "" :
              (<div className={classes.optionInfoList}>
                <div className={classes.optionInfoListItem}>
                  <Typography className={classes.optionInfoTitle}>UEN</Typography>
                  <Typography className={classes.optionInfoValue}>{uen}</Typography>
                </div>
                <div className={classes.optionInfoListItem}>
                  <Typography className={classes.optionInfoTitle}>UEN Status</Typography>
                  <Typography className={classes.optionInfoValue}>{uen_status}</Typography>
                </div>
                <div className={classes.optionInfoListItem}>
                  <Typography className={classes.optionInfoTitle}>Standard Credit Terms</Typography>
                  <Typography className={classes.optionInfoValue}>{standard_credit_terms}</Typography>
                </div>
                <div className={classes.optionInfoListItem}>
                  <Typography className={classes.optionInfoTitle}>Website</Typography>
                  <Typography className={classes.optionInfoValue}>{company_url || 'N/A'}</Typography>
                </div>
                <div className={classes.optionInfoListItem}>
                  <Typography className={classes.optionInfoTitle}>Procurement Policies</Typography>
                  <Typography className={classes.optionInfoValue}>
                    {procurement_policies || 'N/A'}
                  </Typography>
                </div>
                <div className={classes.optionInfoListItem}>
                  <Typography className={classes.optionInfoTitle}>Currency</Typography>
                  <Typography className={classes.optionInfoValue}>{currency}</Typography>
                </div>
                <div className={classes.optionInfoListItem}>
                  <Typography className={classes.optionInfoTitle}>
                    Accounts Payable contact information
                  </Typography>
                  <Typography className={classes.optionInfoValue}>{comment || 'N/A'}</Typography>
                </div>
              </div>)}
            </div>
          );
        }}
        renderInput={params => (
          <div ref={params.InputProps.ref}>
            <input
              type="text"
              {...params.inputProps}
              name="company_query"
              placeholder="Search for companies"
              className={classes.searchAutocompleteInput}
            />
          </div>
        )}
        onInputChange={handleSearchCompany}
        PopperComponent={props => (
          <Popper
            {...props}
            style={{ width: '100%' }}
            anchorEl={searchRef.current}
            container={searchRef.current}
          />
        )}
      />
    </Box>
  </Paper>
  return (
    homeSearch ? searchComponent : (
      <Grid container justifyContent="space-between" alignItems="center" spacing={isMobile ? 3 : 4}>
        <Grid item xs={12} md="auto">
          <Typography variant="h2" className={classes.sectionHeaderText}>
            My companies
          </Typography>
        </Grid>
        {openSearch ? (
          <Grid item xs>
            <Grid container spacing={isMobile ? 1 : 3}>
              <Grid item xs md>
                <RootRef rootRef={searchRef}>
                  {searchComponent}
                </RootRef>
              </Grid>

              <Grid item xs="auto" md="auto">
                <StyledIconButton wid="48px" hei="48px" onClick={handleClaimCompany}>
                  <TimesIcon stroke="#ffffff" fill="#ffffff" />
                </StyledIconButton>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} md={3}>
            <Button color="primary" variant="contained" onClick={handleClaimCompany}>
              Claim your company
            </Button>
          </Grid>
        )}
      </Grid>)
  );
};
