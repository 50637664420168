import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useStyles } from './styles';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

function number_format(number) {
  if (typeof number == 'number') {
    return new Intl.NumberFormat('en', { maximumSignificantDigits: 3 }).format(number);
  }
  return number;
}

export const CalculatorResults = ({ calculatedData }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.resultsContainer}>
      <Typography style={{ color: '#5E6369', fontSize: 20, lineHeight: 1.5 }}>Average in revenue each day</Typography>
      <Box className={classes.resultsMain}>
        <Typography style={{ color: '#77CC21', fontSize: 60, fontWeight: '600', fontFamily: 'Montserrat, sans-serif', paddingLeft: "30px" }}>
          <sup>$ </sup>{number_format(calculatedData.iDoS)}
        </Typography>
      </Box>
      <Grid className={classes.resultItems}>
        {[
          {
            label: 'Monthly interest payable on overdue receivables:',
            value: number_format(calculatedData.mi),
          },
          {
            label: 'On average, it takes your customers',
            value: `${calculatedData.dso} days`,
          },
          {
            label: 'Average cash flow improvement after following RIABU methodology for 100 days: ',
            value: number_format(calculatedData.mi100),
          },
          {
            label: 'Average monthly interest savings after following RIABU methodology for 100 days: ',
            value: number_format(calculatedData.ms100),
          },
          {
            label: 'Average cash flow improvement after following RIABU methodology for 250 days: ',
            value: number_format(calculatedData.mi250),
          },
          {
            label: 'Average monthly interest savings after following RIABU methodology for 250 days:',
            value: number_format(calculatedData.ms250),
          },
        ].map(({ label, value }, index) => (
          <Grid key={label}>
            <Box>
              <Typography style={{ color: '#5E6369', fontSize: 14, lineHeight: 1.5 }}>
                <Tooltip title={label}>
                  <Typography style={{ fontSize: "14px" }}>{index === 1 ? label.slice(0, 10) :
                    label.slice(0, 7)}
                    <InfoOutlinedIcon  style={{fontSize: "12px",marginLeft: "2px" }}/>
                  </Typography>
                </Tooltip>
              </Typography>
            </Box>
            <Typography style={{ color: '#5E6369', fontSize: 18, fontFamily: 'Montserrat, sans-serif', fontWeight: '400' }}>{index === 1 ? ""
              : <sup>$</sup>}{value}</Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
