import React from 'react';
import { Box, Grid, Typography, TextField, FormHelperText, Button } from '@material-ui/core';
import { styled } from '@material-ui/styles';

import { CustomCheckbox } from '@components/common/CustomCheckbox';
import { CustomHelperText } from '@components/common/CustomHelperText';

import { showSuccessNotification, showErrorNotification } from '@components/common/Notifications';
// import { err } from '@constants/messages';

import { Accreditation } from '@api/requests';

import { useFormik } from 'formik';
import * as yup from 'yup';
// import * as validation from '@constants/validation/validation';
import { handleInvalidError } from '@utils/handleInvalidError';

import { useStyles } from './styles';

const AccreditationFormFieldComponent = ({
  mainLabel,
  fileName,
  fileOnChange,
  fileOnBlur,
  reasonLabel,
  reasonName,
  reasonOnChange,
  reasonOnBlur,
  reasonValue,
  screenTipText,
  reason = true,
  fileHelperText,
  fileError,
  reasonHelperText,
  reasonError,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">{mainLabel}</Typography>
      </Grid>
      <Grid item xs={12}>
        <input
          type="file"
          name={fileName}
          onChange={event => {
            fileOnChange(fileName, event.currentTarget.files[0]);
          }}
          onBlur={fileOnBlur}
        />

        {Boolean(fileError && fileHelperText) && <CustomHelperText>{fileHelperText}</CustomHelperText>}
        <FormHelperText>{screenTipText}</FormHelperText>
      </Grid>
      {reason && (
        <Grid item xs={12}>
          <StyledTextField
            type="text"
            name={reasonName}
            label={reasonLabel}
            value={reasonValue}
            onBlur={reasonOnBlur}
            onChange={reasonOnChange}
            helperText={reasonHelperText}
            error={reasonError}
          />
        </Grid>
      )}
    </Grid>
  );
};

const AccreditationFormField = React.memo(AccreditationFormFieldComponent);

export const AccreditationForm = ({ onHandleTriggerUserData }) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      // accreditation_id: '',
      grant_permission: '',
      credit_policy: '',
      credit_policy_reason: '',
      customer_letter: '',
      customer_letter_reason: '',
      sales_certificate: '',
      sales_certificate_reason: '',
      call_certificate: '',
      call_certificate_reason: '',
      top_customer: '',
      top_customer_reason: '',
      order_sample: '',
      order_sample_reason: '',
      invoice_sample: '',
      invoice_sample_reason: '',
      call_log: '',
      call_log_reason: '',
      discrepancy_log: '',
      discrepancy_log_reason: '',
      dso_report: '',
      dso_report_reason: '',
      dso_target: '',
      dso_target_reason: '',
      collections_target: '',
      excerpt_of_minutes: '',
      prefer_to_meet: '',
    },
    validationSchema: yup.object().shape({
      // grant_permission: validation.GRANT_PERMISSION,
      // prefer_to_meet: validation.PREFER_TO_MEET,
    }),
    onSubmit: values => {
      const valuesData = new FormData();
      valuesData.set('grant_permission', +values.grant_permission);

      valuesData.append('credit_policy', values.credit_policy);
      valuesData.append('customer_letter', values.customer_letter);
      valuesData.append('sales_certificate', values.sales_certificate);
      valuesData.append('call_certificate', values.call_certificate);
      valuesData.append('top_customer', values.top_customer);
      valuesData.append('order_sample', values.order_sample);
      valuesData.append('invoice_sample', values.invoice_sample);
      valuesData.append('call_log', values.call_log);
      valuesData.append('discrepancy_log', values.discrepancy_log);
      valuesData.append('dso_report', values.dso_report);
      valuesData.append('dso_target', values.dso_target);
      valuesData.append('collections_target', values.collections_target);
      valuesData.append('excerpt_of_minutes', values.excerpt_of_minutes);

      valuesData.set('credit_policy_reason', values.credit_policy_reason);
      valuesData.set('customer_letter_reason', values.customer_letter_reason);
      valuesData.set('sales_certificate_reason', values.sales_certificate_reason);
      valuesData.set('call_certificate_reason', values.call_certificate_reason);
      valuesData.set('top_customer_reason', values.top_customer_reason);
      valuesData.set('order_sample_reason', values.order_sample_reason);
      valuesData.set('invoice_sample_reason', values.invoice_sample_reason);
      valuesData.set('call_log_reason', values.call_log_reason);
      valuesData.set('discrepancy_log_reason', values.discrepancy_log_reason);
      valuesData.set('dso_report_reason', values.dso_report_reason);
      valuesData.set('dso_target_reason', values.dso_target_reason);
      valuesData.set('collections_target', values.collections_target);
      valuesData.set('excerpt_of_minutes', values.excerpt_of_minutes);

      valuesData.set('prefer_to_meet', +values.prefer_to_meet);

      Accreditation.saveFiles(valuesData)
        .then(res => {
          if (res.success) {
            formik.setSubmitting(false);
            showSuccessNotification(res.data, 3000);
            onHandleTriggerUserData();
          }
        })
        .catch(e => {
          formik.setSubmitting(false);
          if (e.response && e.response.status === 422) {
            handleInvalidError(formik.setErrors)(e);
            showErrorNotification('validation error', 4000);
          } else {
            showErrorNotification('Something was wrong', 4000);
          }
        });
    },
  });
  return (
    <Box className={classes.formBox}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <CustomCheckbox
              title="If you are using the RIABU Lead-to-Cash portal, you may grant us permission to access the documents from your account"
              name="grant_permission"
              value={formik.values.grant_permission}
              onChange={formik.handleChange}
            />
            {Boolean(formik.errors.grant_permission && formik.touched.grant_permission) && (
              <CustomHelperText>{formik.errors.grant_permission}</CustomHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <AccreditationFormField
              mainLabel="If you are unable to provide the document, please state your reasons.Credit policy signed by customer"
              fileName="credit_policy"
              fileOnChange={formik.setFieldValue}
              fileOnBlur={formik.onBlur}
              reasonLabel="I am unable to provide this document because"
              reasonName="credit_policy_reason"
              reasonOnChange={formik.handleChange}
              reasonOnBlur={formik.onBlur}
              reasonValue={formik.values.credit_policy_reason}
              screenTipText="Screen tip: Upload one example of a credit policy which has been signed by a customer."
              fileHelperText={formik.touched.credit_policy && formik.errors.credit_policy}
              fileError={Boolean(formik.touched.credit_policy && formik.errors.credit_policy)}
              reasonHelperText={formik.touched.credit_policy_reason && formik.errors.credit_policy_reason}
              reasonError={Boolean(formik.touched.credit_policy_reason && formik.errors.credit_policy_reason)}
            />
          </Grid>
          <Grid item xs={12}>
            <AccreditationFormField
              mainLabel="Customer letter attached to credit policy"
              fileName="customer_letter"
              fileOnChange={formik.setFieldValue}
              fileOnBlur={formik.onBlur}
              reasonLabel="I am unable to provide this document because"
              reasonName="customer_letter_reason"
              reasonOnChange={formik.handleChange}
              reasonOnBlur={formik.onBlur}
              reasonValue={formik.values.customer_letter_reason}
              screenTipText="Screen tip: Upload the letter or email text you send to your customer along with your credit policy."
              fileHelperText={formik.touched.customer_letter && formik.errors.customer_letter}
              fileError={Boolean(formik.touched.customer_letter && formik.errors.customer_letter)}
              reasonHelperText={formik.touched.customer_letter_reason && formik.errors.customer_letter_reason}
              reasonError={Boolean(formik.touched.customer_letter_reason && formik.errors.customer_letter_reason)}
            />
          </Grid>
          <Grid item xs={12}>
            <AccreditationFormField
              mainLabel="Sales training certificate"
              fileName="sales_certificate"
              fileOnChange={formik.setFieldValue}
              fileOnBlur={formik.onBlur}
              reasonLabel="I am unable to provide this document because"
              reasonName="sales_certificate_reason"
              reasonOnChange={formik.handleChange}
              reasonOnBlur={formik.onBlur}
              reasonValue={formik.values.sales_certificate_reason}
              screenTipText="Screen tip: Upload a copy of the certificate you received when you completed sales training."
              fileHelperText={formik.touched.sales_certificate && formik.errors.sales_certificate}
              fileError={Boolean(formik.touched.sales_certificate && formik.errors.sales_certificate)}
              reasonHelperText={formik.touched.sales_certificate_reason && formik.errors.sales_certificate_reason}
              reasonError={Boolean(formik.touched.sales_certificate_reason && formik.errors.sales_certificate_reason)}
            />
          </Grid>
          <Grid item xs={12}>
            <AccreditationFormField
              mainLabel="Call training certificate"
              fileName="call_certificate"
              fileOnChange={formik.setFieldValue}
              fileOnBlur={formik.onBlur}
              reasonLabel="I am unable to provide this document because"
              reasonName="call_certificate_reason"
              reasonOnChange={formik.handleChange}
              reasonOnBlur={formik.onBlur}
              reasonValue={formik.values.call_certificate_reason}
              screenTipText="Screen tip: Upload a copy of the certificate you received when you completed customer service call training."
              fileHelperText={formik.touched.call_certificate && formik.errors.call_certificate}
              fileError={Boolean(formik.touched.call_certificate && formik.errors.call_certificate)}
              reasonHelperText={formik.touched.call_certificate_reason && formik.errors.call_certificate_reason}
              reasonError={Boolean(formik.touched.call_certificate_reason && formik.errors.call_certificate_reason)}
            />
          </Grid>
          <Grid item xs={12}>
            <AccreditationFormField
              mainLabel="List of top 20% of customers by revenue"
              fileName="top_customer"
              fileOnChange={formik.setFieldValue}
              fileOnBlur={formik.onBlur}
              reasonLabel="I am unable to provide this document because"
              reasonName="top_customer_reason"
              reasonOnChange={formik.handleChange}
              reasonOnBlur={formik.onBlur}
              reasonValue={formik.values.top_customer_reason}
              screenTipText="Screen tip: Upload a list of customers who collectively generate 80% of your revenue, ranked in descending order of revenue. Revenue numbers are not required."
              fileHelperText={formik.touched.top_customer && formik.errors.top_customer}
              fileError={Boolean(formik.touched.top_customer && formik.errors.top_customer)}
              reasonHelperText={formik.touched.top_customer_reason && formik.errors.top_customer_reason}
              reasonError={Boolean(formik.touched.top_customer_reason && formik.errors.top_customer_reason)}
            />
          </Grid>

          <Grid item xs={12}>
            <AccreditationFormField
              mainLabel="Sample order document/quotation"
              fileName="order_sample"
              fileOnChange={formik.setFieldValue}
              fileOnBlur={formik.onBlur}
              reasonLabel="I am unable to provide this document because"
              reasonName="order_sample_reason"
              reasonOnChange={formik.handleChange}
              reasonOnBlur={formik.onBlur}
              reasonValue={formik.values.order_sample_reason}
              screenTipText="Screen tip: Upload an example of your order document or quotation, which clearly states the payment terms."
              fileHelperText={formik.touched.order_sample && formik.errors.order_sample}
              fileError={Boolean(formik.touched.order_sample && formik.errors.order_sample)}
              reasonHelperText={formik.touched.order_sample_reason && formik.errors.order_sample_reason}
              reasonError={Boolean(formik.touched.order_sample_reason && formik.errors.order_sample_reason)}
            />
          </Grid>
          <Grid item xs={12}>
            <AccreditationFormField
              mainLabel="Sample invoice"
              fileName="invoice_sample"
              fileOnChange={formik.setFieldValue}
              fileOnBlur={formik.onBlur}
              reasonLabel="I am unable to provide this document because"
              reasonName="invoice_sample_reason"
              reasonOnChange={formik.handleChange}
              reasonOnBlur={formik.onBlur}
              reasonValue={formik.values.invoice_sample_reason}
              screenTipText="Screen tip: Upload an example of your invoice template, which clearly states the payment terms."
              fileHelperText={formik.touched.invoice_sample && formik.errors.invoice_sample}
              fileError={Boolean(formik.touched.invoice_sample && formik.errors.invoice_sample)}
              reasonHelperText={formik.touched.invoice_sample_reason && formik.errors.invoice_sample_reason}
              reasonError={Boolean(formik.touched.invoice_sample_reason && formik.errors.invoice_sample_reason)}
            />
          </Grid>
          <Grid item xs={12}>
            <AccreditationFormField
              mainLabel="Call log"
              fileName="call_log"
              fileOnChange={formik.setFieldValue}
              fileOnBlur={formik.onBlur}
              reasonLabel="I am unable to provide this document because"
              reasonName="call_log_reason"
              reasonOnChange={formik.handleChange}
              reasonOnBlur={formik.onBlur}
              reasonValue={formik.values.call_log_reason}
              screenTipText="Screen tip: Upload an example of your call log, which records your service calls to your customers."
              fileHelperText={formik.touched.call_log && formik.errors.call_log}
              fileError={Boolean(formik.touched.call_log && formik.errors.call_log)}
              reasonHelperText={formik.touched.call_log_reason && formik.errors.call_log_reason}
              reasonError={Boolean(formik.touched.call_log_reason && formik.errors.call_log_reason)}
            />
          </Grid>
          <Grid item xs={12}>
            <AccreditationFormField
              mainLabel="Discrepancy log"
              fileName="discrepancy_log"
              fileOnChange={formik.setFieldValue}
              fileOnBlur={formik.onBlur}
              reasonLabel="I am unable to provide this document because"
              reasonName="discrepancy_log_reason"
              reasonOnChange={formik.handleChange}
              reasonOnBlur={formik.onBlur}
              reasonValue={formik.values.discrepancy_log_reason}
              screenTipText="Screen tip: Upload an example of your call log, which shows how you track discrepancies which lead to delayed payment."
              fileHelperText={formik.touched.discrepancy_log && formik.errors.discrepancy_log}
              fileError={Boolean(formik.touched.discrepancy_log && formik.errors.discrepancy_log)}
              reasonHelperText={formik.touched.discrepancy_log_reason && formik.errors.discrepancy_log_reason}
              reasonError={Boolean(formik.touched.discrepancy_log_reason && formik.errors.discrepancy_log_reason)}
            />
          </Grid>
          <Grid item xs={12}>
            <AccreditationFormField
              mainLabel="DSO report"
              fileName="dso_report"
              fileOnChange={formik.setFieldValue}
              fileOnBlur={formik.onBlur}
              reasonLabel="I am unable to provide this document because"
              reasonName="dso_report_reason"
              reasonOnChange={formik.handleChange}
              reasonOnBlur={formik.onBlur}
              reasonValue={formik.values.dso_report_reason}
              screenTipText="Screen tip: Upload an example of your DSO (Days Sales Outstanding) report, which tracks how many days your customers have taken to pay."
              fileHelperText={formik.touched.dso_report && formik.errors.dso_report}
              fileError={Boolean(formik.touched.dso_report && formik.errors.dso_report)}
              reasonHelperText={formik.touched.dso_report_reason && formik.errors.dso_report_reason}
              reasonError={Boolean(formik.touched.dso_report_reason && formik.errors.dso_report_reason)}
            />
          </Grid>

          <Grid item xs={12}>
            <AccreditationFormField
              mainLabel="Written DSO reduction target"
              fileName="dso_target"
              fileOnChange={formik.setFieldValue}
              fileOnBlur={formik.onBlur}
              reasonLabel="I am unable to provide this document because"
              reasonName="dso_target_reason"
              reasonOnChange={formik.handleChange}
              reasonOnBlur={formik.onBlur}
              reasonValue={formik.values.dso_target_reason}
              screenTipText="Screen tip: Upload documentary proof that you are working to reduce the number of days it takes your customers to say. This could be a single page, or your policy."
              fileHelperText={formik.touched.dso_target && formik.errors.dso_target}
              fileError={Boolean(formik.touched.dso_target && formik.errors.dso_target)}
              reasonHelperText={formik.touched.dso_target_reason && formik.errors.dso_target_reason}
              reasonError={Boolean(formik.touched.dso_target_reason && formik.errors.dso_target_reason)}
            />
          </Grid>

          <Grid item xs={12}>
            <AccreditationFormField
              mainLabel="Written collections target"
              fileName="collections_target"
              fileOnChange={formik.setFieldValue}
              fileOnBlur={formik.onBlur}
              reason={false}
              screenTipText="Screen tip: Upload documentary proof that you are working to increase the amount of funds collected. This could be a single page, or your policy."
              fileHelperText={formik.touched.collections_target && formik.errors.collections_target}
              fileError={Boolean(formik.touched.collections_target && formik.errors.collections_target)}
            />
          </Grid>

          <Grid item xs={12}>
            <AccreditationFormField
              mainLabel="Excerpt of minutes of management meeting"
              fileName="excerpt_of_minutes"
              fileOnChange={formik.setFieldValue}
              fileOnBlur={formik.onBlur}
              reason={false}
              screenTipText="Screen tip:Upload an example of a management meeting in the last six (6) months which demonstrates that customer payment performance was discussed."
              fileHelperText={formik.touched.excerpt_of_minutes && formik.errors.excerpt_of_minutes}
              fileError={Boolean(formik.touched.excerpt_of_minutes && formik.errors.excerpt_of_minutes)}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomCheckbox
              title="I would prefer to meet you to show you these documents"
              name="prefer_to_meet"
              value={formik.values.prefer_to_meet}
              onChange={formik.handleChange}
            />
            {Boolean(formik.errors.prefer_to_meet && formik.touched.prefer_to_meet) && (
              <CustomHelperText>{formik.errors.prefer_to_meet}</CustomHelperText>
            )}
          </Grid>
          <Grid item xs={4}>
            <Button color="primary" type="submit" variant="contained" disabled={!formik.isValid || formik.isSubmitting}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

const StyledTextField = styled(TextField)({
  '& .MuiInputLabel-formControl': {
    position: 'relative',
  },
});
