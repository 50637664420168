import { api } from '../index';

export const create = billingData => api.post('/billing/subscribe', billingData);

export const cancel = () => api.post('/billing/cancel');

export const accreditation = billingData => api.post('/billing/charge-accreditation', billingData);

export const accreditationPrice = () => api.get('/billing/price-accreditation');

export const payTest = data => api.post('/billing/guest-accreditation', data);
