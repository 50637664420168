import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '48px',
    background: '#FFFFFF',
    border: '1px solid #D8DFE6',
    borderRadius: '16px',
    paddingLeft: 10,
  },
  input: {
    width: '100%',
    height: '100%',
    border: '0',
    padding: '0 10px',
    borderRadius: '16px',
    '&:focus': { border: 0, outline: 0 },
  },
});
