import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { useStyles } from './styles';

export const TalkToAnExpert = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography style={{ color: '#A8ADB2' }} align="center">
        Talk to an expert
      </Typography>
      <Box mt={3.5} mb={3.5}>
        <Typography className={classes.title} align="center">
          Frustrated by customers who aren’t
          <br />
          paying you on time?
        </Typography>
      </Box>
      <Box mb={5}>
        <Typography style={{ color: '#5E6369' }} align="center">
          Get fast, actionable advice on what you can do right now to get your customers to pay you on time.
        </Typography>
      </Box>
      <Grid container justifyContent="center">
        <a
          href="https://riabu.setmore.com/"
          target="_blank"
          style={{ textDecoration: 'none' }}
          rel="noopener noreferrer"
        >
          <Button variant="contained" color="primary" style={{ width: 240 }}>
            Start
          </Button>
        </a>
      </Grid>
    </div>
  );
};
