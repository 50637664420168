import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { CalculatorResults } from './CalculatorResults';
import CalculatorForm from './CalculatorForm';
import { useStyles } from './styles';

export const Calculator = () => {
  const classes = useStyles();

  const [calculatedData, setCalculatedData] = useState({
    iDoS: 0,
    dso: 0,
    mi: 0,
    mi100: 0,
    ms100: 0,
    mi250: 0,
    ms250: 0,
  });

  return (
    <div className={classes.container}>
      <Box mb={8}>
        <Typography className={classes.title}>Calculator</Typography>
      </Box>
      <Grid container justifyContent="space-between" className={classes.content}>
        <Grid item xs={12} sm={12} lg={5}>
          <CalculatorForm onExecuteCalculation={setCalculatedData} />
        </Grid>

        <Grid container xs={12} sm={12} lg={6} alignItems="center" style={{height: "50rem"}}>
          <CalculatorResults calculatedData={calculatedData} />
        </Grid>
      </Grid>
    </div>
  );
};
