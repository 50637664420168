import { makeStyles } from '@material-ui/core/styles';
import { black_main } from '@theme/customTheme';

export const useStyles = makeStyles(theme => ({
  mobileHeaderBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  sectionHeader: {
    fontWeight: '600',
    color: black_main,
    marginRight: '13px',
  },
}));
