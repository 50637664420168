import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { CenteredBox } from '../../../../components/common/CenteredBox';

export const LoadingBox: React.FC<any> = props => {
  const { loading, children } = props;
  return loading ? (
    <CenteredBox>
      <CircularProgress />
    </CenteredBox>
  ) : (
    children
  );
};
