import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green_b, black_main } from 'src/theme/customTheme';
import DownloadIcon from 'src/assets/svg/icons/arrow-link.svg';
import { Link } from 'react-router-dom';
import { Wrapper } from '../../shared/Wrapper';
import { StyledIconButton } from '../common/StyledIconButton';

export const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
    color: '#FFFFFF',
  },
  itemContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemText: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 'bold',
    color: black_main,
    marginRight: '16px',
    '@media (max-width:480px)': {
      fontSize: '18px',
      textAlign: 'center',
    },
  },
  iconBtn: {
    background: '#FFFFFF',
    border: `1.75px solid ${green_b}`,
    '&:hover': {
      background: '#FFFFFF',
    },
  },
}));

interface IProps {
  label: string | React.ElementType;
  link: string;
}

export const PageLinkCard: React.FC<IProps> = props => {
  const { label, link } = props;
  const classes = useStyles();

  return (
    <>
      <Link to={link} className={classes.link}>
        {/* @ts-ignore */}
        <Wrapper isDefaultPadding>
          <div className={classes.itemContent}>
            <Typography className={classes.itemText}>{label}</Typography>
            {/* @ts-ignore */}
            <StyledIconButton className={classes.iconBtn} wid="48" hei="48">
              <img src={DownloadIcon} alt="Arrow" style={{ height: '24px', width: '24px' }} />
            </StyledIconButton>
          </div>
        </Wrapper>
      </Link>
    </>
  );
};
