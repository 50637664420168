import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  formWrapper: {
    width: '500px',
    '@media (max-width: 620px)': {
      width: '300px',
    },
    '@media (max-width: 414px)': {
      width: '250px',
    },
  },
}));
