import React from 'react';

import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { useStyles } from './styles';

export const Situation = ({ title, description }) => {
  const classes = useStyles();

  const history = useHistory();

  const handleRegister = () => history.push('/register');

  if (!description || !title) return null;

  return (
    <div className={classes.container}>
      <Typography style={{ color: '#5E6369', fontSize: 24 }}>{title}</Typography>
      <Box mt={3.5} mb={3.5}>
        <Typography style={{ color: '#040C14', fontSize: 13 }}>{description}</Typography>
      </Box>
      <Typography style={{ color: '#77CC21', cursor: 'pointer' }} onClick={handleRegister}>
        Register
      </Typography>
    </div>
  );
};
