import { makeStyles } from '@material-ui/core/styles';
import { grey_light, green_b, red_b } from '@theme/customTheme';

export const useStyles = makeStyles(theme => ({
  photoBox: {
    width: '128px',
    height: '128px',
    borderRadius: '50%',
    background: grey_light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '@media (max-width:480px)': {
      margin: '0 auto',
    },
  },
  uploadInputBox: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    userSelect: 'none',
  },
  uploadInput: {
    display: 'none',
  },
  uploadBtn: {
    display: 'flex',
    background: green_b,
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  deleteAvatarBtn: {
    border: '2px solid #ffffff',
    background: red_b,
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  avatarImg: {
    display: 'block',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
}));
