/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, Button, Popover } from '@material-ui/core';
import produce from 'immer';
import { grey_medium, theme } from '@theme/customTheme';
import { withStyles } from '@material-ui/core/styles';
import { Wrapper } from '@shared/Wrapper';
import { isMobile } from '@utils/mobileDetect';
import { CustomTextField } from '@components/common/CustomTextField';
import { CustomSelectForm } from '@components/common/CustomSelectFrom';
import { handleInvalidError } from '@utils/handleInvalidError';
import { showErrorNotification, showSuccessNotification } from '@components/common/Notifications';
import { err } from '@constants/messages';

import { CenteredBox } from '@components/common/CenteredBox';
import { ReactComponent as HelpIcon } from '@assets/svg/icons/help-circle.svg';

import { ClaimCompany } from '@api/requests';
import { createClaimOptions, createClaimDaysOptions } from '@utils/createOptions';

import { useFormik } from 'formik';
import * as yup from 'yup';
import * as validation from '@constants/validation/validation';

import { useStyles } from './styles';

export const DiscrepanciesManagementForm = ({ collectionsData }) => {
  const classes = useStyles();

  const [discrepancyTypeOptions, setDiscrepancyTypeOptions] = useState([]);
  const [daysOptions, setDaysOptions] = useState([]);
  const [collectionsDataModel, setCollectionsDataModel] = useState(null);

  const [anchorTooltipInfo, setAnchorTooltipInfo] = useState(null);

  const handleShowTooltipInfo = event => {
    setAnchorTooltipInfo(event.currentTarget);
  };

  const handleHiddenTooltipInfo = () => {
    setAnchorTooltipInfo(null);
  };
  const showTooltipInfo = Boolean(anchorTooltipInfo);

  const localChangeCollectionsDataModel = values => {
    const { claim_discrepancy } = collectionsDataModel;
    const cloneClaimDiscrepancy = claim_discrepancy.map(el => ({ ...el }));
    const findElemIndex = cloneClaimDiscrepancy.findIndex(el => el.type === values.discrepancyType);
    const oldElem = cloneClaimDiscrepancy[findElemIndex];
    const newElem = {
      ...oldElem,
      ...values,
    };
    const newClaimDiscrepancy = [
      ...cloneClaimDiscrepancy.slice(0, findElemIndex),
      newElem,
      ...cloneClaimDiscrepancy.slice(findElemIndex + 1),
    ];

    const modifyCollectionsDataModel = produce(collectionsDataModel, draft => {
      draft.claim_discrepancy = [...newClaimDiscrepancy];
    });
    setCollectionsDataModel(modifyCollectionsDataModel);
  };

  const [allow_to_all_discrepancy, setAllowDiscrepancy] = useState(false);

  const formik = useFormik({
    initialValues: {
      discrepancyType: 1,
      m_customer_service_email: '',
      m_sales_email: '',
      m_branch_manager_email: '',
      m_days_allowed: 1,
      m_email_template: '',
      s_branch_manager_email: '',
      s_country_manager_email: '',
      s_business_unit_email: '',
      s_days_allowed: 1,
      id: undefined,
      claim_id: undefined,
    },
    validationSchema: yup.object().shape({
      m_customer_service_email: validation.EMAIL,
      m_sales_email: validation.EMAIL,
      m_branch_manager_email: validation.EMAIL,
      s_branch_manager_email: validation.EMAIL,
      s_country_manager_email: validation.EMAIL,
      s_business_unit_email: validation.EMAIL,
      m_email_template: validation.RECOMMENDATION,
    }),
    onSubmit: values => {
      localChangeCollectionsDataModel(values);
      ClaimCompany.updateDiscrepancy({ ...values, allow_to_all_discrepancy })
        .then(res => {
          formik.setSubmitting(false);
          showSuccessNotification(res.message, 3000);
        })
        .catch(e => {
          handleInvalidError(formik.setErrors)(e);
          showErrorNotification(err.DEFAULT);
          formik.setSubmitting(false);
        });
    },
  });

  const setFormikInitialValue = modelParam => {
    const { claim_discrepancy } = modelParam;
    const cloneClaimDiscrepancy = claim_discrepancy.map(el => ({ ...el }));

    const claimDiscrepancyDataByOneType = cloneClaimDiscrepancy[0];

    const {
      m_customer_service_email,
      m_sales_email,
      m_branch_manager_email,
      m_days_allowed,
      m_email_template,
      s_branch_manager_email,
      s_country_manager_email,
      s_business_unit_email,
      s_days_allowed,
      type,
      id,
      claim_id,
    } = claimDiscrepancyDataByOneType;
    formik.setFieldValue('id', id, false);
    formik.setFieldValue('claim_id', claim_id, false);
    formik.setFieldValue('discrepancyType', type, false);
    formik.setFieldValue('m_customer_service_email', m_customer_service_email, false);
    formik.setFieldValue('m_sales_email', m_sales_email, false);
    formik.setFieldValue('m_branch_manager_email', m_branch_manager_email, false);
    formik.setFieldValue('m_days_allowed', m_days_allowed, false);
    formik.setFieldValue('m_email_template', m_email_template, false);
    formik.setFieldValue('s_branch_manager_email', s_branch_manager_email, false);
    formik.setFieldValue('s_country_manager_email', s_country_manager_email, false);
    formik.setFieldValue('s_business_unit_email', s_business_unit_email, false);
    formik.setFieldValue('s_days_allowed', s_days_allowed, false);
  };

  const setFormikValue = modelParam => {
    const { claim_discrepancy } = modelParam;
    const cloneClaimDiscrepancy = claim_discrepancy.map(el => ({ ...el }));

    const claimDiscrepancyDataByOneType = cloneClaimDiscrepancy.find(
      ({ type }) => type === +formik.values.discrepancyType
    );

    const {
      m_customer_service_email,
      m_sales_email,
      m_branch_manager_email,
      m_days_allowed,
      m_email_template,
      s_branch_manager_email,
      s_country_manager_email,
      s_business_unit_email,
      s_days_allowed,
      type,
      id,
      claim_id,
    } = claimDiscrepancyDataByOneType;
    formik.setFieldValue('id', id, false);
    formik.setFieldValue('claim_id', claim_id, false);
    formik.setFieldValue('discrepancyType', type, false);
    formik.setFieldValue('m_customer_service_email', m_customer_service_email, false);
    formik.setFieldValue('m_sales_email', m_sales_email, false);
    formik.setFieldValue('m_branch_manager_email', m_branch_manager_email, false);
    formik.setFieldValue('m_days_allowed', m_days_allowed, false);
    formik.setFieldValue('m_email_template', m_email_template, false);
    formik.setFieldValue('s_branch_manager_email', s_branch_manager_email, false);
    formik.setFieldValue('s_country_manager_email', s_country_manager_email, false);
    formik.setFieldValue('s_business_unit_email', s_business_unit_email, false);
    formik.setFieldValue('s_days_allowed', s_days_allowed, false);
  };

  useEffect(() => {
    if (collectionsData && collectionsData.discrepancy_identified && collectionsData.days) {
      const tempDiscrepancyTypeOptions = collectionsData.discrepancy_identified.map(({ id, name }) =>
        createClaimOptions(id, name)
      );
      const tempDaysOptions = collectionsData.days.map(days => createClaimDaysOptions(days));
      setDiscrepancyTypeOptions(tempDiscrepancyTypeOptions);
      setDaysOptions(tempDaysOptions);
      setCollectionsDataModel(collectionsData.model);
      setFormikInitialValue(collectionsData.model);
    }
  }, [collectionsData]);

  useEffect(() => {
    if (collectionsDataModel) {
      setFormikValue(collectionsDataModel);
    }
  }, [formik.values.discrepancyType]);

  function applyToAllDiscrepancies() {
    setAllowDiscrepancy(true);
    formik.handleSubmit();
  }

  function updateInfo() {
    setAllowDiscrepancy(false);
    formik.handleSubmit({ ...formik.values, allow_to_all_discrepancy: false });
  }

  return (
    <form>
      <Grid container className={classes.gridRoot} spacing={isMobile ? 4 : 6}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box className={classes.managmentTitleBox}>
                <Typography className={classes.managmentTitle} variant="h6">
                  Resolution Centre
                </Typography>
                <Box
                  aria-haspopup="true"
                  onMouseEnter={handleShowTooltipInfo}
                  onMouseLeave={handleHiddenTooltipInfo}
                  aria-owns={showTooltipInfo ? 'mouse-over-popover' : undefined}
                >
                  <CenteredBox style={{ cursor: 'pointer' }}>
                    <HelpIcon />
                  </CenteredBox>
                  <HoverPopover
                    id="mouse-over-popover"
                    open={showTooltipInfo}
                    anchorEl={anchorTooltipInfo}
                    onClose={handleHiddenTooltipInfo}
                    style={{ top: '-35px', left: '35px' }}
                  >
                    <p className={classes.tooltipInfoText}>
                      Getting paid on time starts here: Assemble the team you need to help you resolve discrepancies –
                      from your sales team, to those delivering your products/services, to those invoicing your
                      customer. By resolving all discrepancies as quickly and efficiently as possible, your customer has
                      no more excuses for delaying payment.
                    </p>
                  </HoverPopover>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomSelectForm
                name="discrepancyType"
                value={formik.values.discrepancyType}
                options={discrepancyTypeOptions}
                label="Choose Discrepancy Type"
                onHandleChange={formik.handleChange}
                helperText={formik.touched.discrepancyType && formik.errors.discrepancyType}
                error={Boolean(formik.touched.discrepancyType && formik.errors.discrepancyType)}
              />
            </Grid>
            <Grid item xs={12}>
              <Wrapper padding={isMobile ? '16px' : '40px'} border={`1px solid ${grey_medium}`}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="body1" className={classes.textBody1}>
                          Who will resolve this discrepancy?
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={isMobile ? 2 : 1}>
                          <Grid container item xs="auto" spacing={isMobile ? 2 : 3}>
                            <Grid item xs={12} md={6}>
                              <CustomTextField
                                nottu="true"
                                type="email"
                                variant="filled"
                                label="Person #1"
                                onBlur={formik.handleBlur}
                                placeholder="Email address"
                                onChange={formik.handleChange}
                                name="m_customer_service_email"
                                value={formik.values.m_customer_service_email}
                                helperText={
                                  formik.touched.m_customer_service_email && formik.errors.m_customer_service_email
                                }
                                error={Boolean(
                                  formik.touched.m_customer_service_email && formik.errors.m_customer_service_email
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <CustomTextField
                                nottu="true"
                                type="email"
                                label="Person #2"
                                variant="filled"
                                name="m_sales_email"
                                onBlur={formik.handleBlur}
                                placeholder="Email address"
                                onChange={formik.handleChange}
                                value={formik.values.m_sales_email}
                                helperText={formik.touched.m_sales_email && formik.errors.m_sales_email}
                                error={Boolean(formik.touched.m_sales_email && formik.errors.m_sales_email)}
                              />
                            </Grid>
                          </Grid>

                          <Grid container item xs="auto" spacing={isMobile ? 2 : 3}>
                            <Grid item xs={12} md={6}>
                              <CustomTextField
                                nottu="true"
                                type="email"
                                variant="filled"
                                label="Person #3"
                                placeholder="Email address"
                                name="m_branch_manager_email"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.m_branch_manager_email}
                                helperText={
                                  formik.touched.m_branch_manager_email && formik.errors.m_branch_manager_email
                                }
                                error={Boolean(
                                  formik.touched.m_branch_manager_email && formik.errors.m_branch_manager_email
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <CustomSelectForm
                                nottu="true"
                                variant="filled"
                                name="m_days_allowed"
                                value={formik.values.m_days_allowed}
                                options={daysOptions}
                                onHandleChange={formik.handleChange}
                                label="Discrepancy Time Allowed for Managers (days)"
                                helperText={formik.touched.m_days_allowed && formik.errors.m_days_allowed}
                                error={Boolean(formik.touched.m_days_allowed && formik.errors.m_days_allowed)}
                              />
                            </Grid>
                          </Grid>
                          <Grid container item xs="auto" spacing={isMobile ? 2 : 3}>
                            <Grid item xs={12}>
                              <CustomTextField
                                nottu="true"
                                rows={6}
                                multiline
                                type="text"
                                variant="filled"
                                label="Email template"
                                name="m_email_template"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.m_email_template}
                                placeholder="Write your email template"
                                helperText={formik.touched.m_email_template && formik.errors.m_email_template}
                                error={Boolean(formik.touched.m_email_template && formik.errors.m_email_template)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="body1" className={classes.textBody1}>
                          Who will check this discrepancy has been resolved?
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={isMobile ? 2 : 1}>
                          <Grid container item xs="auto" spacing={isMobile ? 2 : 3}>
                            <Grid item xs={12} md={6}>
                              <CustomTextField
                                nottu="true"
                                type="email"
                                variant="filled"
                                label="Person #4"
                                placeholder="Email address"
                                name="s_branch_manager_email"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.s_branch_manager_email}
                                helperText={
                                  formik.touched.s_branch_manager_email && formik.errors.s_branch_manager_email
                                }
                                error={Boolean(
                                  formik.touched.s_branch_manager_email && formik.errors.s_branch_manager_email
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <CustomTextField
                                nottu="true"
                                type="email"
                                variant="filled"
                                label="Person #5"
                                placeholder="Email address"
                                name="s_country_manager_email"
                                helpertextposabsl="true"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.s_country_manager_email}
                                helperText={
                                  formik.touched.s_country_manager_email && formik.errors.s_country_manager_email
                                }
                                error={Boolean(
                                  formik.touched.s_country_manager_email && formik.errors.s_country_manager_email
                                )}
                              />
                            </Grid>
                          </Grid>
                          <Grid container item xs="auto" spacing={isMobile ? 2 : 3}>
                            <Grid item xs={12} md={6}>
                              <CustomTextField
                                nottu="true"
                                type="email"
                                variant="filled"
                                label="Person #6"
                                placeholder="Email address"
                                name="s_business_unit_email"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                helpertextposabsl="true"
                                value={formik.values.s_business_unit_email}
                                helperText={formik.touched.s_business_unit_email && formik.errors.s_business_unit_email}
                                error={Boolean(
                                  formik.touched.s_business_unit_email && formik.errors.s_business_unit_email
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <CustomSelectForm
                                nottu="true"
                                variant="filled"
                                name="s_days_allowed"
                                value={formik.values.s_days_allowed}
                                options={daysOptions}
                                onHandleChange={formik.handleChange}
                                label="Discrepancy Time Allowed for Supervisors (days)"
                                helperText={formik.touched.s_days_allowed && formik.errors.s_days_allowed}
                                error={Boolean(formik.touched.s_days_allowed && formik.errors.s_days_allowed)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Wrapper>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="space-around" spacing={2}>
            <Grid xs={12} md={3} item>
              <Button
                color="primary"
                variant="contained"
                disabled={!formik.isValid || formik.isSubmitting}
                onClick={applyToAllDiscrepancies}
              >
                Apply to all discrepancies
              </Button>
            </Grid>

            <Grid xs={12} md={3} item>
              <Button
                color="primary"
                variant="contained"
                onClick={updateInfo}
                disabled={!formik.isValid || formik.isSubmitting}
              >
                Update info
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

const HoverPopover = withStyles({
  root: {
    pointerEvents: 'none',
    width: 'fix-content',
    height: 'fix-content',
    boxShadow: theme.shadows[3],
  },
})(Popover);
