import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  gridLastTextField: {
    '@media (max-width:480px)': {
      marginBottom: '24px',
    },
  },
  inputHelperText: {
    border: '1px solid red',
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      top: '100%',
    },
  },
}));
