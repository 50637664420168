import React, { useCallback } from 'react';

export function useDialogVisibility(modalVisibility = false) {
  const [isOpened, setOpen] = React.useState<boolean>(modalVisibility);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);
  return {
    isOpened,
    handleOpen,
    handleClose,
  };
}
