import React from 'react';

import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { useStyles } from './styles';

export const Point = ({ order, title, description }) => {
  const classes = useStyles();

  const history = useHistory();

  const handleRegister = () => history.push('/register');

  if (!description || !title) return null;

  return (
    <div className={classes.container}>
      <Typography style={{ color: '#77CC21', fontSize: 80, fontWeight: 'bold' }}>{order}</Typography>
      <Box mt={5} mb={5}>
        <Typography className={classes.title}>{title}</Typography>
      </Box>
      {description !== 'Sign up now' && (
        <Typography style={{ color: '#040C14', fontSize: 14 }}>{description}</Typography>
      )}
      {description === 'Sign up now' && (
        <Typography style={{ color: '#77CC21', fontSize: 14, cursor: 'pointer' }} onClick={handleRegister}>
          {description}
        </Typography>
      )}
    </div>
  );
};
