import React from 'react';
import { Box, Typography, Button, Grid } from '@material-ui/core';

import riabuCashFlow from 'src/assets/home/started-section/riabu-cash-flow.png';

import { useStyles } from './styles';

export const FirstSection = () => {
  const classes = useStyles();

  return (
    <Box className={classes.firstSection}>
      <Box className={classes.startedBox}>
        <Box className={classes.titleBox}>
          <Typography className={classes.title}>Book</Typography>
        </Box>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography className={classes.collectionTitle}>Get serious about collections!</Typography>
            <Box mt={3} mb={3}>
              <Typography style={{ fontSize: 20, color: '#5E6369' }}>
                Let the Cash Flow is now available on Amazon
              </Typography>
            </Box>
            <Box className={classes.getStartedBtnBox}>
              <a
                href="https://www.amazon.com/Let-CashFlow-PracticalCustomers/dp/B09NPD2W23/"
                target="_blank"
                style={{ textDecoration: 'none' }}
                rel="noopener noreferrer"
              >
                <Button color="primary" variant="contained">
                  Buy a book
                </Button>
              </a>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.imageContainer}>
            <img src={riabuCashFlow} alt="books" />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
