import { makeStyles } from '@material-ui/core/styles';
// import { grey_potty, grey_dark, black_main } from '@theme/customTheme';

export const useStyles = makeStyles(theme => ({
  questionsSectionWrapper: {
    '@media (max-width:480px)': {
      padding: '0 20px',
    },
  },
  questionsSection: {
    maxWidth: '880px',
    margin: '0 auto',
    padding: '60px 0 120px 0',
    '@media (max-width:480px)': {
      padding: '80px 0px',
    },
  },
}));
