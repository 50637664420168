import { api } from '../index';

export const getAllList = (masterFileId, params) => api.get(`/service-call/${masterFileId}`, { params });

export const create = serviceCallObj => api.post('/service-call/create', serviceCallObj);

export const update = serviceCallObj => api.post('/service-call/update', serviceCallObj);

export const collection = masterFileId => api.get(`/service-call/create/${masterFileId}`);

export const deleteCall = callId => api.delete(`service-call/delete/${callId}`);

export const logMarkResolved = callId => api.get(`/service-call/as-resolved/${callId}`);

export const logMarkNotResolved = callId => api.get(`/service-call/as-not-resolved/${callId}`);

export const logAlertManager = callId => api.post(`/service-call/alert-manager/${callId}`);

export const getInvoiceAmountByInvoiceNumber = invoiceNumber =>
  api.get(`/service-call/invoice-amount/${invoiceNumber}`);

export const getOrdersByCompanyId = companyId => api.get(`/service-call/orders/${companyId}`);
