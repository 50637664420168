/* eslint-disable no-unused-vars */
import { t } from '../actionTypes';
import { setCurrentUserData } from '../actions/auth';

const initialState = {
  isAuthenticated: false,
  currentUserTokens: {},
  currentUserData: {},
};

export const user = (state = initialState, { type, payload }) => {
  switch (type) {
    case t.SET_CURRENT_USER_TOKENS: {
      return {
        ...state,
        isAuthenticated: !!payload,
        currentUserTokens: payload,
      };
    }
    case t.SET_CURRENT_USER_DATA: {
      return {
        ...state,
        isAuthenticated: !!payload,
        currentUserData: payload,
      };
    }

    // 0 - disabled; 1 - enabled
    case t.SET_CURRENT_USER_MS_STATUS: {
      return {
        ...state,
        currentUserData: {
          ...state.currentUserData,
          profile: {
            ...state.currentUserData.profile,
            ms_status: payload,
          },
        },
      };
    }
    case t.LOGUOT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
