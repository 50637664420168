/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import { isMobile } from '@utils/mobileDetect';
import { CustomTextField } from '@components/common/CustomTextField';
import { Profile } from '@api/requests';
import { showSuccessNotification } from '@components/common/Notifications';
import { isEmptyObj } from '@utils/isEmptyObj';
import { handleInvalidError } from '@utils/handleInvalidError';

import { useFormik } from 'formik';
import * as yup from 'yup';
import * as validation from '@constants/validation/validation';

import { useStyles } from './styles';

export const ProfileInfoForm = ({ userData }) => {
  const classes = useStyles();

  const [profileData, setProfileData] = useState({});

  const formik = useFormik({
    initialValues: {
      tile: '',
      phone: '',
    },
    validationSchema: yup.object().shape({
      tile: validation.TILE,
      phone: validation.PHONE,
    }),
    onSubmit: values => {
      Profile.update(values)
        .then(res => {
          formik.setSubmitting(false);
          showSuccessNotification(res.message);
        })
        .catch(e => {
          formik.setSubmitting(false);
          handleInvalidError(formik.setErrors)(e);
        });
    },
  });

  const setFormikValues = data => {
    formik.setFieldValue('phone', data.phone, false);
    formik.setFieldValue('tile', data.tile, false);
  };

  useEffect(() => {
    if (!isEmptyObj(userData)) {
      setProfileData(userData.profile);
      setFormikValues(userData.profile);
    }
  }, [userData]);

  if (isEmptyObj(profileData)) {
    return <CircularProgress />;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={isMobile ? 2 : 4} alignItems="flex-end">
            <Grid item xs={12} md={4}>
              <CustomTextField
                label="Full name"
                title={profileData.full_name}
                value={profileData.full_name}
                helperTextPosAbsl="true"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomTextField
                label="Company"
                title={profileData.company_name}
                value={profileData.company_name}
                helperTextPosAbsl="true"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomTextField
                type="text"
                name="tile"
                label="Job Title"
                helperTextPosAbsl="true"
                title={formik.values.tile}
                value={formik.values.tile}
                onChange={formik.handleChange}
                helperText={formik.touched.tile && formik.errors.tile}
                error={Boolean(formik.touched.tile && formik.errors.tile)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomTextField
                label="Email"
                title={profileData.email}
                value={profileData.email}
                helperTextPosAbsl="true"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4} className={classes.gridLastTextField}>
              <CustomTextField
                type="text"
                name="phone"
                label="phone"
                helperTextPosAbsl="true"
                title={formik.values.phone}
                value={formik.values.phone}
                onChange={formik.handleChange}
                helperText={formik.touched.phone && formik.errors.phone}
                error={Boolean(formik.touched.phone && formik.errors.phone)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
