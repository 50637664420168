export interface IQuiz {
  id: number;
  question: string;
  yes: number;
  no: number;
  not_sure: number;
  order: number;
}

export const trueQuestions = [3, 5, 6, 7, 9, 11, 14, 17, 19, 21, 22, 23, 24, 27, 30, 32, 33, 35, 38, 39, 40, 44, 45];

export const quizData: IQuiz[] = [
  {
    id: 1,
    question:
      'Are you the most senior manager in your company (for example, are you the President, Chairman, CEO, GM, Managing Director?)',
    yes: 1,
    no: 1,
    not_sure: 0,
    order: 1,
  },
  {
    id: 2,
    question: 'Do you remind your team regularly how important it is for customers to pay on time?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 2,
  },
  {
    id: 3,
    question: 'Do you reinforce the importance of timely payment when you meet each customer?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 3,
  },
  {
    id: 4,
    question: 'Did your team contribute to your "how to get paid" (credit) policy?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 4,
  },
  {
    id: 5,
    question: 'Do you always talk about receivables when you meet your management team?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 5,
  },
  {
    id: 6,
    question: 'Do you measure your sales team on whether customers pay your invoices?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 6,
  },
  {
    id: 7,
    question: 'Have you and your team been trained on working capital management?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 7,
  },
  {
    id: 8,
    question: 'Have you identified the small group of customers who owe you the majority of overdues?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 8,
  },
  {
    id: 9,
    question:
      'Do you call these customers every time shortly after invoicing to ensure the invoice is accurate and will be paid?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 9,
  },
  {
    id: 10,
    question: 'Do you have a detailed, written "how to get paid" policy?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 10,
  },
  {
    id: 11,
    question: 'Has the management team read and agreed to the policy?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 11,
  },
  {
    id: 12,
    question: 'Does the policy state customer payment terms clearly?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 12,
  },
  {
    id: 13,
    question: "Does it state your team's individual responsibilities with regard to collections?",
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 13,
  },
  {
    id: 14,
    question: 'Does it include billing requirements?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 14,
  },
  {
    id: 15,
    question: 'Does it describe your collections process?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 15,
  },
  {
    id: 16,
    question: 'Does it describe your resolution process, in case there is a query about an invoice?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 16,
  },
  {
    id: 17,
    question: 'Does it describe your process for reviewing credit limits?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 17,
  },
  {
    id: 18,
    question: 'Has the "how to get paid" policy document been sent to all your customers?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 18,
  },
  {
    id: 19,
    question: 'Have you sent a letter to existing customers reconfirming the terms?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 19,
  },
  {
    id: 20,
    question:
      'Do you require all customers to sign off on payment terms as part of your very first quotation or contract with your new customers?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 20,
  },
  {
    id: 21,
    question:
      'Do you have on file the names and contact details for the people at your customer who process and pay your invoice?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 21,
  },
  {
    id: 22,
    question: 'Does your order documentation clearly state payment terms?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 22,
  },
  {
    id: 23,
    question: 'Do your invoices clearly state payment terms?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 23,
  },
  {
    id: 24,
    question: 'Do invoices clearly specify the due date?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 24,
  },
  {
    id: 25,
    question:
      'Do invoices clearly state your bank details, including SWIFT, IBAN, BSB and account number, and account name?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 25,
  },
  {
    id: 26,
    question: 'Do invoices clearly state the process to be followed if there is a discrepancy?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 26,
  },
  {
    id: 27,
    question: 'Have you made a list of your top 20% of customers by sales?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 27,
  },
  {
    id: 28,
    question:
      'Do you make a service call your top 20% of customers within three (3) business days of sending your invoice?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 28,
  },
  {
    id: 29,
    question: 'Do you have a list of your high-risk customers who frequently pay you late?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 29,
  },
  {
    id: 30,
    question:
      'Do you make a service call to your high-risk customers within three (3) business days of sending your invoice?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 30,
  },
  {
    id: 31,
    question: 'Do you note down all customer interactions and follow-up actions?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 31,
  },
  {
    id: 32,
    question: 'Do you have a call script which you always use?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 32,
  },
  {
    id: 33,
    question: 'Has your collections team been formally trained on how to conduct service calls?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 33,
  },
  {
    id: 34,
    question: 'Do you note down all reasons customers give (=discrepancies) for withholding payment?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 34,
  },
  {
    id: 35,
    question: 'Do you assign who is responsible for resolving each type of discrepancy?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 35,
  },
  {
    id: 36,
    question: 'Do you track how long it takes to resolve discrepancies?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 36,
  },
  {
    id: 37,
    question: 'Do you know which discrepancies hold up most revenue?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 37,
  },
  {
    id: 38,
    question: 'Do you measure and reward staff who resolve discrepancies promptly?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 38,
  },
  {
    id: 39,
    question: 'Does your sales team get actively involved in resolving discrepancies?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 39,
  },
  {
    id: 40,
    question: 'Do you track and report DSO (Days Sales Outstanding) performance every month?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 40,
  },
  {
    id: 41,
    question: 'Do you set targets to reduce the number of days it takes to get paid?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 41,
  },
  {
    id: 42,
    question: 'Do you track and report overdues of your top 20% of customers?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 42,
  },
  {
    id: 43,
    question: 'Do you track and report overdues of your high-risk customers?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 43,
  },
  {
    id: 44,
    question:
      'Do you track and report how many discrepancies occur of each type, and how much revenue they are holding up?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 44,
  },
  {
    id: 45,
    question: 'Do you measure collections performance of your sales team?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 45,
  },
  {
    id: 46,
    question: 'Is customer payment performance always talked about when your management team meets?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 46,
  },
  {
    id: 47,
    question: 'Do you conduct a credit risk assessment of new customers using external data?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 47,
  },
  {
    id: 48,
    question: 'Do you review customer credit risk annually for all customers?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 48,
  },
  {
    id: 49,
    question: 'Do you calculate and track potential bad debt using a defined formula?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 49,
  },
  {
    id: 50,
    question: 'Do you have a bad debt reduction program agreed with your sales team?',
    yes: 1,
    no: 1,
    not_sure: 1,
    order: 50,
  },
];

export const quizLength = quizData.length;
