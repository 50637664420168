import { api } from '../index';

export const getAll = (orderId, params) => api.get(`/invoices/${orderId}`, { params });

export const changeStatus = invoiceId => api.get(`/invoices/${invoiceId}/change-status`);

export const create = (orderId, data) => api.post(`/invoices/${orderId}`, data);

export const update = (invoiceId, data) => api.post(`/invoices/${invoiceId}/update`, data);

export const deleteInvoice = invoiceId => api.delete(`/invoices/${invoiceId}/destroy`);

export const getUnassignedInvoices = () => api.get(`/invoices/unassigned/get`);

// data ->
// invoice_number:4321
// invoice_amount: 12333
// order_id: 0
export const updateInvoiceById = (invoiceId, data) => api.post(`invoices/${invoiceId}/update`, data)
