import { makeStyles } from '@material-ui/core/styles';
import { green_b, grey_light } from '@theme/customTheme';

export const useStyles = makeStyles(theme => ({
  spamInfoSection: {
    paddingBottom: '60px',
  },
  baseItem: {
    padding: '40px',
    background: grey_light,
    height: '100%',
    '@media (max-width:480px)': {
      padding: '20px',
    },
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  textBox: {
    flexGrow: 1,
  },
  titleBox: {
    marginBottom: '16px',
  },
  linkBox: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  linkText: {
    color: green_b,
    fontFamily: 'Open Sans',
    fontSize: '16px',
    lineHeight: '24px',
    paddingRight: '15px',
  },
  imageBox: {
    '@media (max-width:480px)': {
      marginBottom: '16px',
    },
  },
  image: {
    display: 'block',
    width: '192px',
    height: 'auto',
    '@media (max-width:480px)': {
      width: '100%',
      height: '192px',
    },
  },
  descriptionBox: {
    '@media (max-width:480px)': {
      marginBottom: '24px',
    },
  },
}));
