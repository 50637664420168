/* eslint-disable no-unused-vars */
/* eslint-disable react/state-in-constructor */
import React, { Component } from 'react';

import { FallBackUI } from '../FallBackUI/FallBackUI';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
    });
    console.info(info);
    console.error(error);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <FallBackUI />;
    }

    return children;
  }
}
