import React from 'react';
import { Box } from '@material-ui/core';

import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';

import { useStyles } from './styles';

export const ExternalLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.externalLayout}>
      <Header />
      <Box className={classes.childrenBox}>{children}</Box>
      <Box className={classes.footerBox}>
        <Footer />
      </Box>
    </Box>
  );
};
