import { makeStyles } from '@material-ui/core/styles';
import { grey_dark } from '@theme/customTheme';

export const useStyles = makeStyles(theme => ({
  noItemsBox: {
    borderRadius: '16px',
    border: `1px solid ${grey_dark}`,
    padding: '40px',
    fontSize: '16px',
    color: grey_dark,
    fontFamily: 'Open Sans',
    fontWeight: 'normal',
    lineHeight: '24px',
  },
  listTitle: {
    padding: '32px 0 0 16px',
  }
}));
