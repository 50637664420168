import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  loaderBox: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
  },
}));
