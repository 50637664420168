export const errorMessages = {
  REQUIRED_FIELD: 'This field is required',

  FIRST_NAME_MIN: 'Must be 2 characters or more',
  LAST_NAME_MIN: 'Must be 2 characters or more',

  EMAIL_INVALID: 'Invalid email addresss',
  PASSWORD_MIN: 'Password is too short - should be 8 chars minimum',
  PASSWORD_VALID: 'Password can only contain Latin letters and number and at least one uppercase letter',
  PASSWORD_CONFIRMATION_MATCH: "Passwords doesn't must match",

  TILE: 'Please enter your job Title',
  LINKEDIN: 'Please enter your linkedin link',

  COMPANY_AUTOCOMPLETE: 'The query must be at least 3 characters',
  RECOMMENDATION_MIN: 'Must be 12 characters or more',
  RECOMMENDATION_MAX: 'Must be 500 characters or less',
  RATING: 'The rating must be at least 1',
  MIN_1: 'Must be 1 characters or more',
  MIN_3: 'The query must be at least 3 characters',
  MAX_64: 'Must be 64 characters or less',
  MAX_255: 'Must be 255 characters or less',
  MAX_500: 'Must be 500 characters or less',
  MAX_500_NUM: 'Must be less then 500',
  POSITIE_NUM: 'Field must be positive or 0',
  POSITIVE_NUM: 'Field must be positive',
  CORRECT_INFORMATION_CONFIRMATION: 'The correct information confirmation must be accepted',
  CONFIRMATION_PRODUCT_DELIVERED_ACCORDING_SPECIFICATION:
    'The confirmation product delivered according specification must be accepted',
  CONFIRMATION_INVOICE_ISSUED_INSTRUCTION_OF_CUSTOMER:
    'The confirmation invoice issued instruction of customer must be accepted',
  GRANT_PERMISSION: 'The permission must be accepted',
  PREFER_TO_MEET: 'This field must be accepted',
};
