import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { Point } from './Point';

import { useStyles } from './styles';

const SITUATIONS = [
  {
    title: 'Create a free trial account',
    description: 'Sign up now',
  },
  {
    title: 'Review your customers ',
    description: 'Find out how much faster they are paying other suppliers than they are paying you',
  },
  {
    title: 'Compare your collections performance',
    description: 'Find out how much faster your competitors are getting paid',
  },
  {
    title: 'Take the assessment',
    description: 'Learn what you need to do right now to get paid before other suppliers do',
  },
  {
    title: 'Link your accounting app',
    description: 'Our AI tool scans your financials to help you prioritize customers and structure a collections plan',
  },
  {
    title: 'Join our community',
    description: 'Exchange experiences to address common receivables challenges',
  },
];

export const HowItWorks = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Box mb={8}>
        <Typography className={classes.title}>How it works</Typography>
      </Box>
      <Grid container spacing={6}>
        {SITUATIONS.map(({ title, description }, index) => (
          <Grid key={title} item xs={12} sm={4}>
            <Point order={index + 1} title={title} description={description} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
