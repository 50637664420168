import Swal from 'sweetalert2';

export const showErrorNotification = (msg, delay) => {
  Swal.fire({
    toast: true,
    icon: 'error',
    title: msg,
    position: 'top',
    showConfirmButton: false,
    timer: delay || 3000,
    timerProgressBar: true,
  });
};

export const showWarnNotification = (msg, delay) => {
  Swal.fire({
    toast: true,
    icon: 'warning',
    title: msg,
    position: 'top',
    showConfirmButton: false,
    timer: delay,
    timerProgressBar: true,
  });
};

export const showSuccessNotification = (msg, delay) => {
  Swal.fire({
    toast: true,
    icon: 'success',
    title: msg,
    position: 'top',
    showConfirmButton: false,
    timer: delay || 1000,
    timerProgressBar: true,
  });
};
