/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { loguot } from '@store/actions/auth';

import { Box, Grid, Popover, Paper, Drawer } from '@material-ui/core';
import clsx from 'classnames';
import { SearchCompany } from 'src/components/Header/components/SearchCompany/SearchCompany';
import { CustomMenuList } from '@components/common/CustomMenuList';
import { CustomMenuItem } from '@components/common/CustomMenuItem';
import { CenteredBox } from '@components/common/CenteredBox';
import { isMobile } from '@utils/mobileDetect';
import { Container } from '@components/common/Container';
import { StyledIconButton } from '@components/common/StyledIconButton';
import { ReactComponent as UserIcon } from '@assets/svg/icons/user.svg';
import { ReactComponent as LogoIcon } from '@assets/home/logoIcon.svg';
import logo from '@assets/home/logo.png';

import { useStyles } from './styles';

const HeaderMobile = ({ onUserMenu, openUserMenu, anchorUserMenu, onUserMenuClose }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const classes = useStyles();
  const isLogged = useSelector(state => state.user.isAuthenticated);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(loguot());
  };

  return (
    <Box className={classes.mobileHeaderBox}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <CenteredBox>{/* <BurgerMenuIcon /> */}</CenteredBox>
        </Grid>
        <Grid item xs={4}>
          <CenteredBox>
            <Link to="/">
              <LogoIcon />
            </Link>
          </CenteredBox>
        </Grid>
        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <SearchIcon style={{ marginRight: 10 }} onClick={() => setDrawerOpen(true)} />
          <Drawer anchor="top" open={isDrawerOpen} onClose={() => setDrawerOpen(false)}>
            <div style={{ padding: 10 }}>
              <SearchCompany />
            </div>
          </Drawer>
          <Box onClick={onUserMenu}>
            <UserIcon width="24px" height="24px" fill="#000000" />
            <Popover
              open={openUserMenu}
              style={{ top: 16 }}
              anchorEl={anchorUserMenu}
              onClose={onUserMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Paper className={classes.paper}>
                <CustomMenuList className={classes.userActionList}>
                  {isLogged ? (
                    <Link to="/account/dashboard" className={classes.menuLink}>
                      <CustomMenuItem>My account</CustomMenuItem>
                    </Link>
                  ) : (
                    <Link to="/register" className={classes.menuLink}>
                      <CustomMenuItem>Create account</CustomMenuItem>
                    </Link>
                  )}
                  {isLogged ? (
                    <Link to="/" className={classes.menuLink} onClick={handleLogout}>
                      <CustomMenuItem>Log out</CustomMenuItem>
                    </Link>
                  ) : (
                    <Link to="/login" className={classes.menuLink}>
                      <CustomMenuItem>Login</CustomMenuItem>
                    </Link>
                  )}
                </CustomMenuList>
              </Paper>
            </Popover>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export const Header = () => {
  const classes = useStyles();

  const isLogged = useSelector(state => state.user.isAuthenticated);
  const dispatch = useDispatch();
  const history = useHistory();
  // const [lang, setLang] = useState('en');
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const [anchorUserMenu, setAnchorUserMenu] = useState(null);

  // const handleChangeLang = event => {
  //   setLang(event.target.value);
  // };

  const handleUserMenu = event => {
    setAnchorUserMenu(event.currentTarget);
    setOpenUserMenu(prev => !prev);
  };

  const handleUserMenuClose = () => {
    setAnchorUserMenu(null);
  };

  const handleLogout = () => {
    dispatch(loguot());
  };

  return (
    <Box className={classes.headerBox}>
      <Container>
        {isMobile ? (
          <HeaderMobile
            onUserMenu={handleUserMenu}
            openUserMenu={openUserMenu}
            anchorUserMenu={anchorUserMenu}
            onUserMenuClose={handleUserMenuClose}
          />
        ) : (
          <Grid container className={classes.container} alignItems="center" justify="space-between">
            <Grid item xs={1}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs="auto">
                  <div className={classes.logoBox} onClick={() => history.push('/')}>
                    <img className={classes.logoImg} src={logo} alt="logo" />
                  </div>
                </Grid>
                {/* <Grid item xs="auto">
                  <Select
                    value={lang}
                    onChange={handleChangeLang}
                    className={classes.langSelect}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    input={<SelectInput />}
                  >
                    <CustomMenuItem className={classes.langOptions} value="en">
                      En
                    </CustomMenuItem>
                    <CustomMenuItem className={classes.langOptions} value="ua">
                      Ua
                    </CustomMenuItem>
                  </Select>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <SearchCompany isLogged={isLogged} />
            </Grid>
            <Grid item xs="auto">
              <Box onClick={handleUserMenu}>
                <StyledIconButton
                  wid="48px"
                  hei="48px"
                  className={clsx(classes.userActionBtn, {
                    [classes.userActionBtnActive]: openUserMenu,
                  })}
                >
                  <UserIcon width="24px" height="24px" fill="#000000" />
                </StyledIconButton>
                <Popover
                  open={openUserMenu}
                  style={{ top: 16 }}
                  anchorEl={anchorUserMenu}
                  onClose={handleUserMenuClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Paper>
                    <CustomMenuList className={classes.userActionList}>
                      {isLogged ? (
                        <Link to="/account/dashboard" className={classes.menuLink}>
                          <CustomMenuItem>My account</CustomMenuItem>
                        </Link>
                      ) : (
                        <Link to="/register" className={classes.menuLink}>
                          <CustomMenuItem>Create account</CustomMenuItem>
                        </Link>
                      )}

                      {isLogged ? (
                        <Link to="/" className={classes.menuLink} onClick={handleLogout}>
                          <CustomMenuItem>Log out</CustomMenuItem>
                        </Link>
                      ) : (
                        <Link to="/login" className={classes.menuLink}>
                          <CustomMenuItem>Login</CustomMenuItem>
                        </Link>
                      )}
                    </CustomMenuList>
                  </Paper>
                </Popover>
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>
    </Box>
  );
};

// const SelectInput = withStyles({
//   input: {
//     padding: '0px',
//     fontFamily: 'Open Sans',
//     fontStyle: 'normal',
//     fontWeight: 600,
//     fontSize: '12px',
//     lineHeight: '24px',
//     color: '#000000',
//   },
// })(InputBase);
