import { useEffect, useState } from 'react';
import { trueQuestions, quizLength, quizData } from '../data/quizData';

const isPayedLocalStorage = window.localStorage.getItem('isPayedLocalStorage');
const isQuizDoneLocalStorage = window.localStorage.getItem('isQuizDoneLocalStorage');

const quizIdLocalStorage = window.localStorage.getItem('quizIdLocalStorage');
const scoreLocalStorage = window.localStorage.getItem('scoreLocalStorage');
const downScoreLocalStorage = window.localStorage.getItem('downScoreLocalStorage');
const messageNumberLocalStorage = window.localStorage.getItem('messageNumberLocalStorage');

const useQuiz = () => {
  const [quizId, setQuizId] = useState<number>(Number(quizIdLocalStorage || 0));
  const [score, setScore] = useState<number>(Number(scoreLocalStorage || 0));
  const [downScore, setDownScore] = useState<number>(Number(downScoreLocalStorage || 0));
  const [isQuizDone, setIsQuizDone] = useState<boolean>(isQuizDoneLocalStorage === 'true');
  const [messageNumber, setMessageNumber] = useState<number>(Number(messageNumberLocalStorage || 0));

  const [isPayed, setIsPayed] = useState<boolean>(isPayedLocalStorage === 'true');

  const currentQuestion = quizData[quizId];

  const saveQuiz = () => {
    window.localStorage.setItem('quizIdLocalStorage', String(quizId));
    window.localStorage.setItem('scoreLocalStorage', String(score));
    window.localStorage.setItem('downScoreLocalStorage', String(downScore));
    window.localStorage.setItem('messageNumberLocalStorage', String(messageNumber));
  };

  const nextQuiz = (isAddScore: boolean) => {
    if (quizLength > quizId + 1) {
      if (isAddScore === true) {
        if (trueQuestions.includes(quizId + 1)) {
          setScore(prev => prev + 1);
        } else {
          setDownScore(prev => prev + 1);
        }
      }
      setQuizId(prev => prev + 1);
    } else if (quizLength === quizId + 1) {
      if (isAddScore === true) {
        if (trueQuestions.includes(quizId + 1)) {
          setScore(prev => prev + 1);
        } else {
          setDownScore(prev => prev + 1);
        }
      }

      window.localStorage.setItem('isQuizDoneLocalStorage', 'true');
      window.localStorage.setItem('isPayedLocalStorage', 'true');

      if (score >= 23 && downScore >= 15) {
        setMessageNumber(2);
      } else if ((score >= 15 && downScore < 23) || downScore >= 10) {
        setMessageNumber(3);
      } else if (score >= 21 && downScore >= 15) {
        setMessageNumber(2);
      } else if ((score >= 15 && downScore < 21) || downScore >= 10) {
        setMessageNumber(3);
      } else {
        setMessageNumber(4);
      }

      setIsQuizDone(true);
    }
  };

  useEffect(() => {
    window.localStorage.setItem('messageNumberLocalStorage', String(messageNumber));
  }, [messageNumber]);

  return {
    quizId,
    nextQuiz,
    currentQuestion,
    score,
    downScore,
    quizLength,
    saveQuiz,
    isQuizDone,
    messageNumber,
    isPayed,
    setIsPayed,
  };
};

export default useQuiz;
