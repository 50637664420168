import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { payTest } from 'src/api/requests/biling';
import CloseIcon from '@material-ui/icons/Close';
import useQuiz from '../hooks/useQuiz';
import { AccreditationForm } from '../../AccreditationForm/AccreditationForm';
import { StripeForm } from '../../../../components/StripeForm/StripeForm';

const AssessmentModal: FC<{ isQuizModalVisible: boolean; handleToggleQuizModalVisibility: () => void }> = props => {
  const { isQuizModalVisible, handleToggleQuizModalVisibility } = props;
  const {
    quizId,
    nextQuiz,
    currentQuestion,
    quizLength,
    saveQuiz,
    isQuizDone,
    messageNumber,
    isPayed,
    setIsPayed,
  } = useQuiz();
  const [currentAnswer, setCurrentAnswer] = useState(null);

  const handleChangeAnswer = (event: any) => setCurrentAnswer(event.currentTarget.value);

  const testElement = (
    <>
      <Dialog open={isQuizModalVisible} onClose={handleToggleQuizModalVisibility} maxWidth="sm">
        {isPayed ? (
          <>
            <LinearProgress variant="determinate" value={(100 / quizLength) * quizId} />
            <DialogTitle>{currentQuestion.question}</DialogTitle>
            <DialogContent>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={currentAnswer}
                onChange={handleChangeAnswer}
                color="primary"
              >
                {currentQuestion.yes ? <FormControlLabel value="1" control={<Radio />} label="Yes" /> : null}
                {currentQuestion.no ? <FormControlLabel value="2" control={<Radio />} label="No" /> : null}
                {currentQuestion.not_sure ? <FormControlLabel value="3" control={<Radio />} label="Not sure" /> : null}
              </RadioGroup>
            </DialogContent>
            <DialogActions>
              <Grid container justify="space-between">
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      saveQuiz();
                      handleToggleQuizModalVisibility();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      nextQuiz(currentAnswer === '1');
                      setCurrentAnswer(null);
                      saveQuiz();
                    }}
                    disabled={!currentAnswer}
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </>
        ) : (
          <div style={{ padding: 20 }}>
            <IconButton
              aria-label="delete"
              style={{ display: 'block', marginLeft: 'auto' }}
              onClick={() => handleToggleQuizModalVisibility()}
              size="small"
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
            <Typography variant="h3">$25.00</Typography>
            <StripeForm
              onHandleToggleStripeModalVisability={() => {
                setIsPayed(true);
                window.localStorage.setItem('isPayedLocalStorage', 'true');
              }}
              apiRequest={payTest}
              withoutLogin
            />
          </div>
        )}
      </Dialog>
    </>
  );

  const answerQuestions = (
    <>
      <Grid item xs={12}>
        {(messageNumber === 2 || messageNumber === 3) && (
          <Box style={{padding: "5%"}}>
            <Box>
              <Typography variant="h3">
                Thank you for taking the RIABU assessment. The results show that there are significant gaps in your
                collections process which prevent us from accrediting your company.
              </Typography>
            </Box>
            <Box>
              <Typography>To do so:</Typography>
              <Typography>1.) Taking the 9-week “RIABU Collections Accelerator” email course (free)</Typography>
              <Typography>2.) Watching the “RIABU Collections Accelerator” videos (free)</Typography>
              <Typography>3.) Taking the one (1) hour “RIABU Collections Accelerator” course on UDEMY</Typography>
              <Typography>
                4.) Booking a customised 1:1 review of your RIABU assessment results. One of our receivables experts
                will walk you through where improvements are needed before we can grant you an accreditation
                certificate.
              </Typography>
            </Box>
            <Box sx={{margin: "10px 0px"}}>
              <Typography style={{margin: "10px 0px"}}>Your Score Is: {localStorage.getItem('scoreLocalStorage')}</Typography>
              <Typography style={{margin: "10px 0px"}}>Have a call with a RIABU consultant to ask them where you went wrong,
               and what you can do about it!</Typography>
              <Button style={{color: "#FFFFFF", backgroundColor: "#89CC21", width: "max-content", margin: "10px 0px"}}>Get In Touch</Button>
            </Box>
          </Box>
        )}
        {messageNumber === 4 && (
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box>
                  <Typography variant="h3">
                    Congratulations! You have successfully passed the self-assessment quiz and we are ready to provide
                    you with RIABU Accreditation.
                  </Typography>

                  <Typography>To do so:</Typography>

                  <Typography>1.) Pay the US$250 Accreditation fee, and</Typography>
                  <Typography>2.) Submit documents to prove compliance</Typography>

                  <Typography>What happens then?</Typography>
                  <Typography>
                    Upon successful submission of substantiating documents, we will provide you with an accreditation
                    certificate, and details of how you can get discounted financing with one of our financial services
                    partners.
                  </Typography>

                  <Typography>What documents do I need to provide?</Typography>
                  <Typography>
                    To demonstrate you are following the RIABU Collections Accelerator, we need to verify the following
                    documents.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <AccreditationForm onHandleTriggerUserData={() => 0} />
              </Grid>
            </Grid>
          </Box>
        )}
      </Grid>
    </>
  );

  const isPayedElement = <>{!isQuizDone ? testElement : answerQuestions}</>;

  return isPayedElement || null;
};

export default AssessmentModal;
