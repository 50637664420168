import { api } from '../index';

export const register = data => api.post('/register', data);

export const login = data => api.post('/oauth/token', data);

export const requestResetPassword = data => api.post('/password/create', data);

export const resetPasswordIsValidToken = token => api.get(`/password/find/${token}`);

export const resetPassword = data => api.post('/password/reset', data);

export const verifyToken = token => api.post(`/confirm/${token}`);

export const getQrMsCode = () => api.get('/two-factor');

export const activeTwoFactor = data => api.post('/two-factor/activate', data);

export const disableTwoFactor = data => api.post('two-factor/disable', data);
