import { Box, Button, Grid, Typography, Slider } from '@material-ui/core'
import React, { useState } from 'react'
import { useStyles } from './styles'
import { useHistory } from 'react-router'

const HomepageQuiz = () => {
  const history = useHistory();
  const [quesNum, setQuesNum] = useState(0)
  const [results, setResults] = useState([])
  const classes = useStyles()
  const allQuestions = [
    "ANGRY AT YOUR CUSTOMER?",
    "How angry are you at your customer now?",
    "Has this customer left you hanging before?",
    "Did you call the customer about your overdue invoice?",
    "Did you call your customer before your invoice was due?",
    "Has the customer confirmed they got your invoice?",
    "Did the customer agree to your payment terms?",
    "Did the customer confirm your products and services were delivered to specifications?",
    "Did you log your calls with your customer?",
    "We've identified ___ reasons why you might not have been paid on time. Would you like to solve these problems right now?",]

  const questions = allQuestions.map(q =>
  (<Typography className={classes.NewQuizQues}>
    {q}
  </Typography>));
  const answerHandler = (answer) => {
    setResults(x => ([...x, { answer: answer, question: allQuestions[quesNum] }]))
    if (quesNum === 9 && answer === "yes") {
      history.push("/account/knowledge-base")
    }
    setQuesNum(x => x += 1)
    console.log(results)
  }

  const marks = [
    {
      value: 0,
      label: 'A bit pissed off',
    },
    {
      value: 50,
      label: 'Angry',
    },
    {
      value: 100,
      label: "I'm very angry",
    },
  ];

  return (
    <Box className={classes.NewQuizBox}>
      {questions[quesNum] ?
        (<Grid className={classes.NewQuizContainer}>
          <Box>
            {questions[quesNum]}
          </Box>
          {quesNum > 1 ?
            <Box className={classes.NewQuizBtns}>
              <Button onClick={() => { answerHandler("yes") }} color='primary' variant='contained'>Yes</Button>
              <Button onClick={() => { answerHandler("no") }} color="secondary" variant='contained'>No</Button>
              {quesNum === 4 || quesNum === 5 || quesNum === 6 ?
                (<Button onClick={() => { answerHandler("Not sure") }} color="secondary" variant='contained'>Not sure</Button>) : ""}
              {quesNum === 7 || quesNum === 8 ?
                (<Button onClick={() => { answerHandler("Don't know") }} color="secondary" variant='contained'>Don't know</Button>) : ""}
            </Box> :
            <Box className={classes.NewQuizSlider}>
              {quesNum ?
                (<><Slider style={{ width: "50%" }} name="Anger Bar" marks={marks} step={50}
                  defaultValue={0} aria-label="Default" />
                  <Button onClick={() => { answerHandler("Angry") }} color='primary' variant='contained'>Answer</Button></>)
                : <Button style={{marginTop: "0px"}} onClick={() => { setQuesNum(x => x += 1) }} color='primary' variant='contained'>Click Here</Button>}
            </Box>}
        </Grid>) :
        (
          <Box className={classes.NewQuizDoneContainer}>
            <Typography className={classes.NewQuizQues}>
              You said you were angry. Take action! You can solve this!
            </Typography>
            <Button onClick={() => { history.push("/account/my-customers/create-mf") }} color="secondary" variant='contained'>Solve This</Button>
          </Box>)}
    </Box>
  )
}

export default HomepageQuiz