import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    NewQuizBox: {
        padding: "1%",
        margin: "7% 0%",
        textAlign: "center",
    },
    NewQuizContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        minHeight: "10rem",
        '@media (max-width:550px)': {
            minHeight: "8rem",
        },
    },
    NewQuizQues: {
        fontSize: "30px",
        fontWeight: "bold",
        lineHeight: "40px",
        '@media (max-width:550px)': {
            fontSize: "20px",
            lineHeight: "25px",
        },
    },
    NewQuizBtns: {
        display: "flex",
        justifyContent: "space-evenly",
        width: "50%",
        margin: "20px auto",
        '@media (max-width:400px)': {
            alignItems: "center",
            minHeight: "12rem",
            flexDirection: "column",
            justifyContent: "space-evenly",
            marginTop: "15px",
        },
        "& button": {
            width: "130px",
            '@media (max-width:950px)': {
                fontSize: "11px",
            },
            '@media (max-width:400px)': {
                width: "82%",
                fontSize: "13px",
            }

        }
    },
    NewQuizSlider: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& button": {
            width: "130px",
            marginTop: "30px",
            '@media (max-width:950px)': {
                fontSize: "11px",
                width: "100px",
            },
        }
    },
    NewQuizDoneContainer: {
        "& button": {
            marginTop: "50px",
            width: "130px",
            '@media (max-width:950px)': {
                fontSize: "11px",
                width: "100px",
            },
        }
    },
}));
