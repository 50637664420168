import { makeStyles } from '@material-ui/core/styles';

import { grey_dark, black_light, red_b, orange_b } from '@theme/customTheme';
import { green_b } from 'src/theme/customTheme';


export const getStatusBtnWrapper = (c) => {
  return {
    border: `2px solid ${c}`,
    background: 'transparent',
    borderRadius: '16px',
    height: '48px',
    padding: '0px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    color: c,
    fontWeight: 600,
  }
}

export const declinedBtn = getStatusBtnWrapper(red_b);
export const pendingBtn = getStatusBtnWrapper(orange_b);
export const successBtn = getStatusBtnWrapper(green_b);

export const transparentTrashIcon = {
  background: 'rgba(231, 64, 64, 0.1)',
  '&:hover': {
    background: '#ffffff',
  },
  '& .MuiIconButton-label': {
    '& path': {
      fill: red_b,
    },
  },
}

export const useStyles = makeStyles(theme => ({
  companyNameBox: {
    marginBottom: '5px',
  },
  companyName: {
    textTransform: 'uppercase',
  },
  companyPhone: {
    fontSize: '16px',
    color: grey_dark,
    lineHeight: '120%',
    fontWeight: 'normal',
  },
  editIcon: {
    border: `2px solid ${black_light}`,
    background: '#ffffff',
    '&:hover': {
      background: '#ffffff',
    },
  },
  trashIcon: transparentTrashIcon,
  pendingBtn,
  declinedBtn,
  successBtn,
}));
