import React from 'react';
import { SimpleTable } from 'src/components/common/SimpleTable/SimpleTable';
import { IEmployee, onDeleteAccessType } from './types';
import { EmployeeRow } from './EmployeeTableRow';

interface IEmployeeTableProps {
  onDeleteAccess: onDeleteAccessType;
  data: Array<IEmployee>;
}

export const EmployeeTable: React.FC<IEmployeeTableProps> = props => {
  const { data, onDeleteAccess } = props;
  return (
    <SimpleTable>
      {data.map(row => (
        <EmployeeRow key={row.id} row={row} onDeleteAccess={onDeleteAccess} />
      ))}
    </SimpleTable>
  );
};
