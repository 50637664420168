import { makeStyles } from '@material-ui/core/styles';
import { green_b, black_main } from '@theme/customTheme';

export const useStyles = makeStyles(theme => ({
  summatyText: {
    color: black_main,
  },
  summatyActiveText: {
    fontWeight: 600,
    color: green_b,
  },
  detailsText: {},
}));
