import { api } from '../index';

export const getAll = companyId => api.get('/claim/get-list', { companyId });

export const create = companyId => api.post('/claim/create', companyId);

export const deleteCompany = companyId => api.delete(`/claim/delete/${companyId}`);

export const upadate = data => api.post('/claim/update', data);

export const updateDiscrepancy = data => api.post('/claim/update-discrepancy', data);

export const getCollections = id => api.get(`claim/get-collection/${id}`);
