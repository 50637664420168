/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import { CenteredBox } from '@components/common/CenteredBox';
import { showErrorNotification, showSuccessNotification } from '@components/common/Notifications';

import { ClaimCompany } from '@api/requests';

import { AccessedCompanyItem } from 'src/pages/MyCompanies/MyProfile/AccessedCompanyItem/AccessedCompanyItem';
import { deleteEmployeeMultiAccess } from 'src/api/requests/multiAccess';
import { CompaniesListItem } from '../CompaniesListItem/CompaniesListItem';
import { useStyles } from './styles';
import Pledge2Pay from '../../../Pledge2Pay/Pledge2Pay';

export const CompaniesList = ({ onUpdateCompany, response, onTriggerUpdateListData, isLoading, error }) => {
  const classes = useStyles();

  const [companies, setCompanies] = useState([]);
  const [isLoadingLoc, setIsLoadingLoc] = useState(false);

  const [accessedCompanies, setAccessedCompanies] = useState([]);

  const handleDeleteCompany = async id => {
    let res;
    setIsLoadingLoc(true);
    try {
      res = await ClaimCompany.deleteCompany(id);
    } catch (e) {
      showErrorNotification(e, 3000);
    }
    setIsLoadingLoc(false);
    showSuccessNotification(res.message, 3000);
    onTriggerUpdateListData();
  };

  const handleLeaveCompany = async accessId => {
    let res;
    setIsLoadingLoc(true);
    try {
      res = await deleteEmployeeMultiAccess(accessId);
    } catch (e) {
      showErrorNotification(e, 3000);
    }
    setIsLoadingLoc(false);
    showSuccessNotification(res.data || 'You have successfully left company', 3000);
    onTriggerUpdateListData();
  };

  useEffect(() => {
    if (response && response.companies) {
      setCompanies(response.companies);
      setAccessedCompanies(response.access_company);
    } else {
      setCompanies([]);
      setAccessedCompanies([]);
    }
  }, [response]);

  return (
    <Grid style={{minHeight: "70vh",alignContent:"space-between"}}
      container spacing={3}>
      <Grid>
        {(isLoadingLoc || isLoading) && (
          <Grid item xs={12}>
            <CenteredBox>
              <CircularProgress />
            </CenteredBox>
          </Grid>
        )}
        <Grid xs={12}>
          <Typography variant="h4" className={classes.listTitle}>
            Claimed companies
          </Typography>
        </Grid>

        {!isLoading &&
          !isLoadingLoc &&
          companies.map((company, index) => (
            <Grid item xs={12} key={company.id}>
              <CompaniesListItem
                data={company}
                index={index}
                onDeleteCompany={handleDeleteCompany}
                onUpdateCompany={onUpdateCompany}
              />
            </Grid>
          ))}

        <Grid xs={12}>
          <Typography variant="h4" className={classes.listTitle}>
            Accessed companies
          </Typography>
        </Grid>

        {!isLoading &&
          !isLoadingLoc &&
          accessedCompanies.map((access, index) => (
            <Grid item xs={12} key={access.id}>
              <AccessedCompanyItem data={access} index={index} onLeaveCompany={handleLeaveCompany} />
            </Grid>
          ))}

        {!companies.length ||
          (error && (
            <Grid item xs={12}>
              <CenteredBox className={classes.noItemsBox}>No items</CenteredBox>
            </Grid>
          ))}
      </Grid>
      <Pledge2Pay />
    </Grid >
  );
};
